import React, { useState, useContext } from "react";
import { UserContext } from "../App";
import SettingsModal from "./SettingsModal";

import axios from "axios";

const ProfileSection = () => {
  const { user, setUser, isFetching } = useContext(UserContext);
  const [newUserName, setNewUserName] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isEditingNameModalOpen, setIsEditingNameModalOpen] = useState(false);

  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const handleEdit = () => {
    setIsEditingNameModalOpen(true);
    setNewUserName(user.name);
    setIsEditing(true);
  };

  const handleSettingChange = () => {
    setIsSettingsModalOpen(true);
  };
  const handleCancel = () => {
    setIsEditing(false);
  };
  const fetchAndSaveName = async ({ user_name, new_user_name }) => {
    try {
      await axios.patch(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/changeUserInfo`,
        {
          params: { user_name: user_name, new_user_name: new_user_name },
        },
      );

      setUser((currentUser) => ({
        ...currentUser,
        name: new_user_name,
      }));
      setErrorMessage("");
    } catch (error) {
      let message = "An unexpected error occurred. Please try again.";
      if (error.response) {
        if (error.response.status === 400) {
          message =
            error.response.data.message === "User name already in use."
              ? "Username taken"
              : error.response.data.message;
        }
      }
      setErrorMessage(message);
    }
  };

  const handleSave = () => {
    const lowerCaseNewUserName = newUserName.toLowerCase();

    setUser((currentUser) => ({
      ...currentUser,
      new_user_name: lowerCaseNewUserName,
    }));
    setNewUserName(lowerCaseNewUserName);
    setIsEditing(false);
    fetchAndSaveName({
      user_name: user.name,
      new_user_name: lowerCaseNewUserName,
    });
  };

  return isFetching ? (
    <p>Fetching...</p>
  ) : (
    <div className="mt-2 flex flex-col rounded-lg border border-gray-200 bg-white text-black shadow-md dark:border-gray-700 dark:bg-gray-800 sm:items-center">
      {/* Email Field */}
      <div className="mb-4 w-full px-2 md:mb-0 md:w-1/2">
        <label
          htmlFor="email"
          className="text-lg font-semibold text-gray-700 dark:text-gray-300"
        >
          Email:
        </label>
        <input
          type="email"
          id="email"
          name="email"
          value={user.email.toLowerCase()}
          readOnly
          className="w-full rounded-md border-2 border-gray-300 p-2 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 dark:border-gray-600"
          required
        />
      </div>

      {/* Name Field */}
      <div className="mb-4 flex w-full px-2 md:mb-0 md:mt-4 md:w-1/2">
        {isEditing ? (
          <div className="w-full">
            <label
              htmlFor="name"
              className="text-lg font-semibold text-gray-700 dark:text-gray-300"
            >
              Name:
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={newUserName}
              onChange={(e) => setNewUserName(e.target.value.trim())}
              className="w-full rounded-md border-2 border-gray-300 p-2 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 dark:border-gray-600"
            />
            {isEditingNameModalOpen && (
              <div className="mt-2 flex">
                <button
                  onClick={handleSave}
                  className="rounded-md bg-green-500 px-4 py-2 text-white hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-200 focus:ring-opacity-50"
                >
                  Save
                </button>
                <button
                  onClick={handleCancel}
                  className="ml-2 rounded-md bg-red-500 px-4 py-2 text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-200 focus:ring-opacity-50"
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
        ) : (
          <div className="flex w-full items-center">
            <label
              htmlFor="name"
              className="mr-2 text-lg font-semibold text-gray-700 dark:text-gray-300"
            >
              Name:
            </label>
            <span className="mr-2 text-gray-800 dark:text-gray-200">
              {user.name}
            </span>
            <div className="flex flex-col">
              <button
                onClick={handleEdit}
                className="rounded-md bg-blue-500 px-4 py-2 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-200 focus:ring-opacity-50"
              >
                &#x270E; Edit
              </button>
            </div>
          </div>
        )}
      </div>

      {/* Password Field */}
      <div className="flex w-full flex-col items-start px-2 md:w-1/2">
        <label
          htmlFor="password"
          className="mr-2 text-lg font-semibold text-gray-700 dark:text-gray-300"
        >
          Password:
        </label>
        <button
          className="mb-2 rounded-md bg-purple-500 px-4 py-2 text-white hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-purple-200 focus:ring-opacity-50"
          onClick={handleSettingChange}
        >
          Settings
        </button>
        {isSettingsModalOpen && (
          <SettingsModal
            isOpen={isSettingsModalOpen}
            onClose={() => setIsSettingsModalOpen(false)}
            user={user}
          />
        )}
      </div>
    </div>
  );
};

export default ProfileSection;
