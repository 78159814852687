import React from "react";
import { CircleCheck, Lightbulb } from "lucide-react";
import "tippy.js/dist/tippy.css";
import { Sentence } from "./Sentence"; // Adjust the path as needed

export interface AnnotationBlockProps {
  sentence: Sentence;
  isHovered: boolean;
  onHover: (id: string | number) => void;
  onLeave: () => void;
}

export const AnnotationBlock: React.FC<AnnotationBlockProps> = ({
  sentence,
  isHovered,
  onHover,
  onLeave,
}) => {
  if (!sentence.annotation) return null;

  return (
    <div
      className={`relative rounded border bg-white p-2 transition-colors duration-200 ${
        sentence.icon === "tick" ? "border-blue-500" : "border-red-500"
      } ${isHovered ? "bg-blue-200" : ""} mt-6 shadow`}
      onMouseEnter={() => onHover(sentence.uniqueId)}
      onMouseLeave={onLeave}
    >
      <div className={`flex items-center ${isHovered ? "bg-blue-200" : ""}`}>
        <div className="pl-2 text-sm">
          {sentence.icon === "tick" && (
            <CircleCheck className="h-8 w-8 text-green-600" />
          )}
          {sentence.icon === "light_bulb" && (
            <Lightbulb className="h-8 w-8 text-red-500" />
          )}
        </div>
        <button className="flex-shrink-0 rounded-full bg-gray-100 p-1.5 text-sm">
          {sentence.id}
        </button>
        <div className="pl-2 text-xs text-gray-600">{sentence.annotation}</div>
      </div>
      <div className="absolute left-0 top-1/2 -translate-x-full -translate-y-1/2 transform">
        <div className="h-px w-6 bg-blue-200"></div>
      </div>
    </div>
  );
};
