import { useContext } from "react";
import { UserContext } from "../App";
import { mainTopics } from "./topics";
import useExercises from "./../hooks/useExercises";
import useUserScores from "./../hooks/useUserScores";

const ScoresSection = () => {
  const { user } = useContext(UserContext);
  const { exercises } = useExercises();

  const apiEndpoint = process.env.REACT_APP_SERVER_ENDPOINT;

  const { scores, isFetching, hasFetched, error } = useUserScores(
    user,
    apiEndpoint,
  );

  if (!scores) {
    return (
      <p className="text-gray-700">Loading scores and exercises data...</p>
    );
  }

  let subtopicDisplayNames = {};
  let maxScoreMapping = {};
  exercises.forEach((exercise) => {
    maxScoreMapping[exercise.id] = exercise.maxScore;
    subtopicDisplayNames[exercise.id] = exercise.displayName;
  });

  return (
    <div className="m-5 flex flex-col rounded-lg border border-gray-200 bg-white p-4 shadow-md">
      {mainTopics.map((topic) =>
        topic.subtopics.map((subtopic, index) => {
          const score = scores[subtopic];
          const maxScore = maxScoreMapping[subtopic];
          const displayName = subtopicDisplayNames[subtopic] || subtopic;
          let content;
          if (score !== undefined && maxScore !== undefined) {
            content = (
              <span className="whitespace-nowrap font-medium text-blue-600">
                {score}/{maxScore} ({((score / maxScore) * 100).toFixed(1)}%)
              </span>
            );
          } else {
            content = hasFetched ? (
              <span className="whitespace-nowrap font-medium text-gray-500">
                Score not available
              </span>
            ) : (
              <span className="whitespace-nowrap font-medium text-gray-500">
                Fetching...
              </span>
            );
          }
          return (
            <div
              key={`${topic.name}-${index}`}
              className="flex items-center py-2 text-gray-800"
            >
              <span className="mr-2 font-semibold">{displayName}:</span>
              <div className="flex-1"></div>
              {content}
            </div>
          );
        }),
      )}
      {isFetching && (
        <p className="mt-2 text-sm text-gray-500">Updating scores...</p>
      )}
      {error && (
        <p className="mt-2 text-sm text-red-500">Error fetching scores.</p>
      )}
    </div>
  );
};

export default ScoresSection;
