import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "./../App";

const HomePage = () => {
  const { isAuthenticated } = useContext(UserContext);

  return (
    <div className="min-h-screen bg-gray-100 text-gray-900 dark:bg-gray-900 dark:text-gray-100">
      {/* Hero Section */}
      {/* <header className="relative flex h-96 items-center justify-center bg-gradient-to-br from-blue-600 to-indigo-700 text-white"> */}
      {/* <div className="text-center">
          <h1 className="text-4xl font-bold sm:text-5xl">
            Welcome to SkillMaster
          </h1>
          <p className="mt-4 text-lg sm:text-xl">
            Improve your knowledge with interactive exercises and challenges.
          </p>
          <div className="mt-6 flex justify-center gap-4">
            <Link
              to="/sign-up"
              className="rounded-lg bg-white px-6 py-3 font-semibold text-blue-600 shadow transition hover:bg-gray-200"
            >
              Get Started
            </Link>
            {isAuthenticated === false && (
              <Link
                to="/sign-in"
                className="rounded-lg border border-white px-6 py-3 font-semibold text-white transition hover:bg-white hover:text-blue-600"
              >
                Sign In
              </Link>
            )}
          </div>
        </div> */}
      {/* </header> */}

      {/* Features Section */}
      <section className="mx-auto max-w-6xl px-6 py-16">
        <h2 className="mb-10 text-center text-3xl font-bold">
          Jacky's playground
        </h2>
        <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
          {/* Feature 1 */}
          <Link
            to="/regex"
            className="transform rounded-lg bg-white p-6 shadow-lg transition hover:scale-105 dark:bg-gray-800"
          >
            <h3 className="text-xl font-semibold">Python Tagger</h3>
            <p className="mt-2 text-gray-600 dark:text-gray-400">
              Python Tagger is used to attach parts of speech tags to any given
              text
            </p>
          </Link>

          {/* Feature 2 */}
          <Link
            to="/tts"
            className="transform rounded-lg bg-white p-6 shadow-lg transition hover:scale-105 dark:bg-gray-800"
          >
            <h3 className="text-xl font-semibold">Text to Speech (TTS)</h3>
            <p className="mt-2 text-gray-600 dark:text-gray-400">
              Using 302ai.com API to convert text to speech
            </p>
          </Link>
          <Link
            to="/annotate"
            className="transform rounded-lg bg-white p-6 shadow-lg transition hover:scale-105 dark:bg-gray-800"
          >
            <h3 className="text-xl font-semibold">Annotation Practice</h3>
            <p className="mt-2 text-gray-600 dark:text-gray-400">
              React component displaying essays with hoverable sentence
              annotations and paragraph grouping functionality.
            </p>
          </Link>

          {/* Feature 3
          <Link
            to="/leaderboard"
            className="transform rounded-lg bg-white p-6 shadow-lg transition hover:scale-105 dark:bg-gray-800"
          >
            <h3 className="text-xl font-semibold">Leaderboard</h3>
            <p className="mt-2 text-gray-600 dark:text-gray-400">
              Compete with others and see where you rank.
            </p>
          </Link> */}

          {/* Feature 4 */}
          {/* <Link
            to="/dashboard"
            className="transform rounded-lg bg-white p-6 shadow-lg transition hover:scale-105 dark:bg-gray-800"
          >
            <h3 className="text-xl font-semibold">Personal Dashboard</h3>
            <p className="mt-2 text-gray-600 dark:text-gray-400">
              Track your progress and performance in one place.
            </p>
          </Link> */}

          {/* Feature 5 */}
          {/* <Link
            to="/dashboard/scores"
            className="transform rounded-lg bg-white p-6 shadow-lg transition hover:scale-105 dark:bg-gray-800"
          >
            <h3 className="text-xl font-semibold">Score Tracking</h3>
            <p className="mt-2 text-gray-600 dark:text-gray-400">
              Review your scores and achievements.
            </p>
          </Link> */}

          {/* Feature 6 */}
        </div>
      </section>

      {/* Call to Action */}
      {/* <section className="bg-blue-600 py-16 text-center text-white">
        <h2 className="text-3xl font-bold">Ready to Get Started?</h2>
        <p className="mt-4 text-lg">
          Join SkillMaster and take your learning to the next level.
        </p>
        <Link
          to="/sign-up"
          className="mt-6 inline-block rounded-lg bg-white px-8 py-3 font-semibold text-blue-600 shadow transition hover:bg-gray-200"
        >
          Sign Up Now
        </Link>
      </section> */}
    </div>
  );
};

export default HomePage;
