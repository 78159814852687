// contextMenuUtils.js
export const getAdjustedMenuPosition = (
  x,
  y,
  menuWidth = 192,
  menuHeight = 250,
) => {
  const offset = 10;
  const viewportWidth = window.innerWidth;
  const viewportHeight = window.innerHeight;

  return {
    x: x + menuWidth > viewportWidth ? viewportWidth - menuWidth - offset : x,
    y:
      y + menuHeight > viewportHeight
        ? viewportHeight - menuHeight - offset
        : y,
  };
};

export const handleContextMenu = (
  e,
  { setShowMenu, setSelectedWord, setContextMenuPos },
) => {
  e.preventDefault();
  const textarea = e.target;
  if (!textarea.value.trim()) {
    setShowMenu(false);
    return;
  }

  const caretPos = textarea.selectionStart;
  const text = textarea.value;
  const wordRegex = /[\w'-]+/g;
  let match;
  let foundWord = null;

  while ((match = wordRegex.exec(text)) !== null) {
    if (match.index <= caretPos && wordRegex.lastIndex >= caretPos) {
      foundWord = {
        word: match[0],
        start: match.index,
        end: wordRegex.lastIndex,
      };
      break;
    }
  }

  if (!foundWord) {
    setShowMenu(false);
    return;
  }

  // Provide visual feedback by selecting the word temporarily
  textarea.setSelectionRange(foundWord.start, foundWord.end);
  setTimeout(() => textarea.setSelectionRange(caretPos, caretPos), 200);

  setSelectedWord(foundWord.word);
  setContextMenuPos(getAdjustedMenuPosition(e.clientX, e.clientY));
  setShowMenu(true);
};
