import { Sentence } from "./Sentence"; // Adjust the path based on your project structure

export const sentences: Sentence[] = [
  {
    uniqueId: "A-1",
    title: "A",
    isParagraphStart: true,
    id: 1,
    text: "Burnout among the workforce has become increasingly problematic in recent years.",
    annotation:
      "Presents brief background: 'Burnout is increasingly problematic.'",
    icon: "tick",
  },
  {
    uniqueId: "A-2",
    title: "A",
    isParagraphStart: false,
    id: 2,
    text: "According to Browner (2022), burnout is a global issue and can be associated with constant feelings of fatigue, tiredness, and depression, with a majority of the workforce approximately 70% of workers experiencing this phenomenon. Some root causes of burnout include inadequate compensation relative to the labor contributed and harsh treatment by colleagues.",
    annotation: "Defines the key term in the essay ‘burnout’.",
    icon: "tick",
  },
  {
    uniqueId: "A-3",
    title: "A",
    isParagraphStart: false,
    id: 3,
    text: "By understanding these causes, companies can implement preventive measures, such as fair compensation for effort and bullying prevention.",
    annotation:
      "Outlines the problems and solutions that will be presented in the essay.",
    icon: "tick",
  },
  {
    uniqueId: "A-4",
    title: "A",
    isParagraphStart: false,
    id: 4,
    text: "This essay explores the causes of workplace burnout and suggests solutions to address these underlying problems.",
    annotation:
      "Gives an overview of the essay. The writer has paraphrased the question and not just copied the 'title'.",
    icon: "tick",
  },
  {
    uniqueId: "A-5",
    title: "A",
    isParagraphStart: true,
    id: 5,
    text: "A major contributor to burnout among employees in the workplace is effort-reward imbalance.",
    annotation: "Gives a clear topic sentence (effort-reward imbalance).",
    icon: "tick",
  },
  {
    uniqueId: "A-6",
    title: "A",
    isParagraphStart: false,
    id: 6,
    text: "Rugulies et al. (2017) reveals that when the effort exerted by employees is not adequately compensated or recognized, it can lead to feelings of unappreciation, degradation, and diminished self-worth, which may ultimately result in depression.",
    annotation:
      "Explains the key term (effort-reward imbalance) and the problems it causes.",
    icon: "tick",
  },
  {
    uniqueId: "A-7",
    title: "A",
    isParagraphStart: false,
    id: 7,
    text: "Effort-reward imbalance has also been observed to be linked to a 1.5 times increase in depression rates, negatively affecting the performance and productivity of workers.",
    annotation: "Better to give a source for statistics.",
    icon: "light_bulb",
  },
  {
    uniqueId: "A-8",
    title: "A",
    isParagraphStart: false,
    id: 8,
    text: "When employees perceive that their efforts and dedication are not met with appropriate rewards whether financial compensation, promotions, or recognition from colleagues and management they may experience dissatisfaction or disappointment.",
    annotation: "Elaborates on the previous two sentences.",
    icon: "tick",
  },
  {
    uniqueId: "A-9",
    title: "A",
    isParagraphStart: false,
    id: 9,
    text: "This can increase the likelihood of developing depression, fatigue, or exhaustion, which are common diagnoses of burnout.",
    annotation: "",
    icon: "NA",
  },
  {
    uniqueId: "A-10",
    title: "A",
    isParagraphStart: false,
    id: 10,
    text: "Companies often provide minimal compensation for employees' efforts, such as yoga classes or wellness apps.",
    annotation: "Introduces current solutions.",
    icon: "tick",
  },
  {
    uniqueId: "A-11",
    title: "A",
    isParagraphStart: false,
    id: 11,
    text: "However, these solutions tend to be short-term fixes for deeper issues that remain unaddressed.",
    annotation: "",
    icon: "NA",
  },
  {
    uniqueId: "A-12",
    title: "A",
    isParagraphStart: false,
    id: 12,
    text: "(Christian, 2023).",
    annotation:
      "The citation appears to be in the wrong position. It should come at the end of the previous sentence.",
    icon: "light_bulb",
  },
  {
    uniqueId: "A-13",
    title: "A",
    isParagraphStart: false,
    id: 13,
    text: "By ignoring these root causes, companies might jeopardize their own success, as employees experiencing burnout will likely see reduced productivity and diminished loyalty, which subsequently affects their responsibilities at work and increases turnover rates, leading to further challenges for the organization.",
    annotation: "Explains how this issue affects companies.",
    icon: "tick",
  },
  {
    uniqueId: "A-14",
    title: "A",
    isParagraphStart: true,
    id: 14,
    text: "To address the problem of burnout among workers, companies should implement counterstrategies.",
    annotation: "Links to the previous paragraph.",
    icon: "tick",
  },
  {
    uniqueId: "A-15",
    title: "A",
    isParagraphStart: false,
    id: 15,
    text: "One effective approach to reduce the impact of burnout caused by a lack of rewards relative to effort is to introduce a comprehensive reward system.",
    annotation: "Introduces the main idea of the paragraph.",
    icon: "tick",
  },
  {
    uniqueId: "A-16",
    title: "A",
    isParagraphStart: false,
    id: 16,
    text: "Nkouangas (2023) observed that both financial rewards, such as bonuses, and forms of recognition, such as employee of the month, are well received by workers, who appreciate that their dedication is acknowledged.",
    annotation:
      "Explains the idea (comprehensive reward system) introduced in the previous sentence.",
    icon: "tick",
  },
  {
    uniqueId: "A-17",
    title: "A",
    isParagraphStart: false,
    id: 17,
    text: "He noted, “Employees enthusiastically support salary increases, which motivate them to work harder,” illustrating that when companies value their employees' efforts, it fosters motivation",
    annotation: "Gives a quotation to support the idea.",
    icon: "tick",
  },
  {
    uniqueId: "A-18",
    title: "A",
    isParagraphStart: false,
    id: 18,
    text: "(p. 23).",
    annotation: "Gives page number for direct quotation.",
    icon: "tick",
  },
  {
    uniqueId: "A-19",
    title: "A",
    isParagraphStart: false,
    id: 19,
    text: "Employers should consider implementing such measures, as motivated workers contribute positively to the company. Compensation plays a significant role in enhancing employee performance (Riaz & Syed, 2020). Improved performance and productivity among employees can lead to increased efficiency, innovation, and overall profitability for the businesses.",
    annotation: "Further explain the argument introduced by the quotation.",
    icon: "tick",
  },
  {
    uniqueId: "A-20",
    title: "A",
    isParagraphStart: false,
    id: 20,
    text: "Furthermore, fostering motivation helps decrease the likelihood of employees feeling undervalued or discouraged, thereby reducing the chances of depression or burnout and promoting a more productive and effective atmosphere for the company.",
    annotation: "Introduces a second argument (stops problems occurring).",
    icon: "tick",
  },
  {
    uniqueId: "A-21",
    title: "A",
    isParagraphStart: false,
    id: 21,
    text: "This investment in employees' well being not only enhances their morale but is also beneficial in the organization's long term success.",
    annotation: "Gives a concluding sentence.",
    icon: "tick",
  },
  {
    uniqueId: "A-22",
    title: "A",
    isParagraphStart: true,
    id: 22,
    text: "In addition to the disparity between compensation and the effort employees invest, another significant factor contributing to workplace dissatisfaction is the harsh treatment many receive from their colleagues.",
    annotation:
      "Gives a clear topic sentence with a paragraph hook and a clear controlling idea (workplace bullying).",
    icon: "tick",
  },
  {
    uniqueId: "A-23",
    title: "A",
    isParagraphStart: false,
    id: 23,
    text: "Workplace bullying has a detrimental effect on the overall work environment, leading to a range of negative consequences for victims.",
    annotation:
      "Explains that the ‘harsh treatment’ introduced in the previous sentence",
    icon: "tick",
  },
  {
    uniqueId: "A-24",
    title: "A",
    isParagraphStart: false,
    id: 24,
    text: "Those who experience bullying often face a dramatic decline in motivation, an increase in mental health issues, reduced job satisfaction, and diminished commitment to their organization (Teo et al, 2019). Being subjected to such bullying creates a hostile work environment, where victims must contend with constant stress from negative interactions. This persistent strain can significantly increase the chances of burnout.",
    annotation:
      "Lists the negative consequences of bullying and links it to burnout.",
    icon: "tick",
  },
  {
    uniqueId: "A-25",
    title: "A",
    isParagraphStart: false,
    id: 25,
    text: "Ribeiro et al (2022) notes that “workplace bullying promotes high levels of burnout in victims” (p. 832).",
    annotation:
      "Provides evidence for the claims made in the previous sentence.",
    icon: "tick",
  },
  {
    uniqueId: "A-26",
    title: "A",
    isParagraphStart: false,
    id: 26,
    text: " The adverse effects of bullying extend beyond individual employees, ultimately impairing performance during their shifts and negatively impacting overall company productivity. Thus, both the employees and the organization suffer as a result of this toxic dynamic.",
    annotation: "Provides summary sentences.",
    icon: "tick",
  },
  {
    uniqueId: "A-27",
    title: "A",
    isParagraphStart: true,
    id: 27,
    text: "Regarding workplace aggression,",
    annotation:
      "'Regarding ..' to  link ideas  is better avoided in academic writing.",
    icon: "light_bulb",
  },
  {
    uniqueId: "A-28",
    title: "A",
    isParagraphStart: false,
    id: 28,
    text: "organizations should consider implementing bullying prevention measures. To combat unacceptable such behavior, anti-bullying policies must be prioritized.",
    annotation: "States the main idea of the paragraph.",
    icon: "tick",
  },
  {
    uniqueId: "A-29",
    title: "A",
    isParagraphStart: false,
    id: 29,
    text: "These measures have proven effective in reducing bullying within the workforce (Jang et al, 2022).",
    annotation: "Provides support by citing an expert.",
    icon: "tick",
  },
  {
    uniqueId: "A-30",
    title: "A",
    isParagraphStart: false,
    id: 30,
    text: "To protect victims, anti-bullying strategies should include open communication between employees and employers, detailed reporting procedures, and training for both staff and management. To deter harassment, companies need to establish clear consequences for such behavior.",
    annotation: "Explains the anti-bullying measures.",
    icon: "tick",
  },
  {
    uniqueId: "A-31",
    title: "A",
    isParagraphStart: false,
    id: 31,
    text: "Additionally, allowing anonymous reporting can help protect the victim's identity. Once reports are received, the organization can initiate an investigation to verify the claims if substantiated, appropriate action can be taken against the aggressor. Implementing these measures will increase awareness of employee's actions and the potential consequences (Strandmark & Rahm, 2014).",
    annotation: "Explains why and how the measures will be effective.",
    icon: "tick",
  },
  {
    uniqueId: "A-32",
    title: "A",
    isParagraphStart: false,
    id: 32,
    text: "By decreasing the rates of bullying in the workplace, organizations can mitigate the negative impacts on employees, thereby reducing the likelihood of burnout in this environment.",
    annotation: "Closes the paragraph by restating the major argument.",
    icon: "tick",
  },
  {
    uniqueId: "A-33",
    title: "A",
    isParagraphStart: true,
    id: 33,
    text: "To conclude, the issue of burnout among the workforce arises from effort-reward imbalances and workplace bullying.",
    annotation: "Restates the problem (i.e., the two causes).",
    icon: "tick",
  },
  {
    uniqueId: "A-34",
    title: "A",
    isParagraphStart: false,
    id: 34,
    text: "Implementing an effective reward system whether through financial incentives or recognition alongside anti-bullying strategies aimed at protecting victims is essential for reducing burnout rates. Companies must invest more effort and resources into creating a healthier working environment, as this would not only support employees but also boost the company performance.",
    annotation: "Summarises proposed solutions.",
    icon: "tick",
  },
  {
    uniqueId: "A-35",
    title: "A",
    isParagraphStart: false,
    id: 35,
    text: "By addressing these critical factors, businesses can foster a more motivated and engaged workforce. Investing in these areas is crucial for reducing burnout rates and fostering a more productive and engaged workforce. Furthermore, raising awareness can lead to a global recognition of such issues and prompt actions to protect the employees.",
    annotation: "Restates writer’s main idea in the closing sentence.",
    icon: "tick",
  },
  {
    uniqueId: "B-1",
    title: "B",
    isParagraphStart: true,
    id: 1,
    text: "Sleep deprivation refers to a continuous pattern of inadequate sleep duration or quality. It cumulates adverse effects on various aspects of one’s well-being, called “sleep debt” (Wise, 2018).",
    annotation:
      "Defines the topic and gives a citation to add academic credibility.",
    icon: "tick",
  },
  {
    uniqueId: "B-2",
    title: "B",
    isParagraphStart: false,
    id: 2,
    text: "It is known that university students are prone to suffer from sleep deprivation.",
    annotation:
      "Better place background sentences at the start of the paragraph.",
    icon: "light_bulb",
  },
  {
    uniqueId: "B-3",
    title: "B",
    isParagraphStart: false,
    id: 3,
    text: "Research has found that students usually sleep at around 2:30am, sleeping for 6.5 hours on average (Amenabar, 2023).",
    annotation:
      "Gives a citation to add academic credibility and avoid plagiarism.",
    icon: "tick",
  },
  {
    uniqueId: "B-4",
    title: "B",
    isParagraphStart: false,
    id: 4,
    text: "This means that most university students are likely to be sleep-deprived.",
    annotation: "",
    icon: "NA",
  },
  {
    uniqueId: "B-5",
    title: "B",
    isParagraphStart: false,
    id: 5,
    text: "Another study indicates that there is a negative correlation between the amount of sleep and working memory and executive function (Duplessis, 2024). Linking both studies, it shows that sleep deprivation may pose adverse effects on academic performance of university students.",
    annotation: "Introduces why this is a problem.",
    icon: "tick",
  },
  {
    uniqueId: "B-6",
    title: "B",
    isParagraphStart: false,
    id: 6,
    text: "A relatively poor grade-point-average and the incapability to participate in lectures would be discussed below. To alleviate the negative effects, caffeine consumption and adding nap stations on campus should be taken into consideration.",
    annotation:
      "Outlines the content of the essay. This would be better if it contained a paraphrase of the main idea of each paragraph.",
    icon: "light_bulb",
  },
  {
    uniqueId: "B-7",
    title: "B",
    isParagraphStart: true,
    id: 7,
    text: "The major effect of sleep deprivation among university students is a relatively poor grade-point-average (GPA).",
    annotation: "Gives topic sentence stating the main idea of the paragraph.",
    icon: "tick",
  },
  {
    uniqueId: "B-8",
    title: "B",
    isParagraphStart: false,
    id: 8,
    text: "Students need good quality and a sufficient amount of sleep to perform well in their studies, as basic brain functions like memorization, attention span, processing and retrieval of knowledge requires sleep, and these functions are most likely necessary for tertiary education (Amenabar, 2023). It is especially important for mid-term tests and examinations, as students usually need to memorize and understand theories or concepts, then apply them on questions that involve some type of analysis or logical thinking, which means that grades are mostly dependent on students’ ability to memorize and ponder.",
    annotation: "Explains the importance of sleep.",
    icon: "tick",
  },
  {
    uniqueId: "B-9",
    title: "B",
    isParagraphStart: false,
    id: 9,
    text: "According to a study of factors influencing academic performance within undergraduate students, ‘‘Sound and enough sleep are important to understand the subject and tackle the exam well.’’ (p.46) is quoted from a student (Abdulghani et al., 2014).",
    annotation: "Provides a quotation to support the main idea.",
    icon: "tick",
  },
  {
    uniqueId: "B-10",
    title: "B",
    isParagraphStart: false,
    id: 10,
    text: "This shows that sleep is a crucial factor that determines one’s comprehension skills, hence affecting GPA.",
    annotation:
      "Explains the previous sentence using a summary phrase 'This shows…'.",
    icon: "tick",
  },
  {
    uniqueId: "B-11",
    title: "B",
    isParagraphStart: false,
    id: 11,
    text: "Moreover, some statistical data can also suggest that sleep duration of undergraduates directly correlates with their GPA.",
    annotation: "Better to avoid being vague, e.g. “Some statistical data…”.",
    icon: "light_bulb",
  },
  {
    uniqueId: "B-12",
    title: "B",
    isParagraphStart: false,
    id: 12,
    text: " A study of effects of chronic sleep deprivation on academic performance states that there is a significant difference shown statistically between students with excellent and average grades concerning excessive daytime sleepiness (Mirghani et al., 2015). Some situations are used to test participants’ sleepiness, 98.3% and 77.1% of average students and excellent students respectively shows signs of sleepiness for “Lying down in the afternoon” (p.469), 81.7% and 52% of average students and excellent students respectively for “Watching TV” (p.469). These percentages show a significant difference between both categories, all indicating that excessive daytime sleepiness is more likely to be found within students with average grades than excellent grades.",
    annotation:
      "Provides statistics to support the main idea. It would be better to make the contrast clearer, e.g., ‘While 98.3% of average students showed signs of daytime sleepiness, the number was lower for excellent students (77.1%).’",
    icon: "light_bulb",
  },
  {
    uniqueId: "B-13",
    title: "B",
    isParagraphStart: false,
    id: 13,
    text: "Therefore, it suggests that sleep deprivation may contribute to a lower GPA.",
    annotation: "Summarizes the main idea of the paragraph.",
    icon: "tick",
  },
  {
    uniqueId: "B-14",
    title: "B",
    isParagraphStart: true,
    id: 14,
    text: "To address the effect of poor GPA caused by sleep deprivation, it is recommended that caffeine consumption can be a short-term solution to reduce students’ daytime sleepiness.",
    annotation:
      "Gives topic sentence with main idea (drink coffee) and links paragraph 2 and 3.",
    icon: "tick",
  },
  {
    uniqueId: "B-15",
    title: "B",
    isParagraphStart: false,
    id: 15,
    text: "Considering mid-term tests and examinations as the main criteria of assessment of academic performance, caffeine consumption can instantly alleviate drowsiness, helping students to stay alert during exams.",
    annotation: "Explains how caffeine can help.",
    icon: "tick",
  },
  {
    uniqueId: "B-16",
    title: "B",
    isParagraphStart: false,
    id: 16,
    text: "According to an investigation on effects of caffeine on sleep, results show that caffeine intake lessens the feeling of insufficient sleep, which could act as a solution to help students attain a higher GPA (Reichert et al., 2020).",
    annotation: "Provides statistical support with a clear citation.",
    icon: "tick",
  },
  {
    uniqueId: "B-17",
    title: "B",
    isParagraphStart: false,
    id: 17,
    text: "Despite potential downside of caffeine consumption mentioned in the study, this can act as an instant solution to improve sleep-deprived students’ GPA.",
    annotation:
      "Includes a new idea in the final sentence of the paragraph (the potential downsides). Better to explain this further.",
    icon: "light_bulb",
  },
  {
    uniqueId: "B-18",
    title: "B",
    isParagraphStart: true,
    id: 18,
    text: "Another negative effect of sleep deprivation on undergraduate students is the probable incapability to participate and focus on lectures.",
    annotation:
      "Better if the topic sentence includes ‘attendance’ which is also discussed in the paragraph.",
    icon: "light_bulb",
  },
  {
    uniqueId: "B-19",
    title: "B",
    isParagraphStart: false,
    id: 19,
    text: "A continuous cycle of insufficient sleep might develop a “sleep debt” gradually, which impairs students' ability to concentrate and engage with the class (Amenabar, 2023). Since the common symptoms of daytime sleepiness are dozing and falling asleep, students might doze off in the middle of lectures or zone out as attention spans are reduced. This might result in students not engaging and focusing during class, unable to fight off the urge to fall asleep.",
    annotation:
      "Explains poor in-class engagement with citation to support the argument.",
    icon: "tick",
  },
  {
    uniqueId: "B-20",
    title: "B",
    isParagraphStart: false,
    id: 20,
    text: "The study of effects of chronic sleep deprivation mentioned above, shows that the chance of dozing was the highest while sitting and reading (88%) which directly determine students’ capability to participate, as this is usually the learning format of tertiary education (Mirghani et al., 2015).",
    annotation: "Provides statistics to support the main idea.",
    icon: "tick",
  },
  {
    uniqueId: "B-21",
    title: "B",
    isParagraphStart: false,
    id: 21,
    text: " Moreover, attendance appears to be an important factor regarding academic performance. Abdulghani et al. (2014) quoted another undergraduate stating that attendance of lectures, and the ability to concentrate while absorbing new concepts is crucial to actually learn the information.",
    annotation: "",
    icon: "NA",
  },
  {
    uniqueId: "B-22",
    title: "B",
    isParagraphStart: false,
    id: 22,
    text: "Therefore, sleep deprivation may influence students’ incapability to participate and focus on lectures.",
    annotation:
      "Summarizes main points. However, it does not include all the main points because'attendance' is not mentioned.",
    icon: "light_bulb",
  },
  {
    uniqueId: "B-23",
    title: "B",
    isParagraphStart: true,
    id: 23,
    text: "To address the effect of incapability to participate in class caused by sleep deprivation, it is recommended to add napping stations to university campus.",
    annotation:
      "Gives clear topic sentence with main idea ‘adding napping stations'.",
    icon: "tick",
  },
  {
    uniqueId: "B-24",
    title: "B",
    isParagraphStart: false,
    id: 24,
    text: "Wise (2018) stated that compared to test respondents who did not nap at all after learning, those who napped right away and those whose naps were postponed performed significantly better on recall and relational interpretation. This shows that napping could be beneficial to students’ engagement during lectures and to actually learn in class. Wise (2018) suggests that undergraduates might nap unintentionally during lectures or while working, therefore access to napping stations can make napping on campus feasible, which encourages students to nap intentionally. This can help students deal with sleep deprivation, not only can they regain a boost of energy to get through the day, but also avoid dozing off in class.",
    annotation: "Explains how napping stations improve support.",
    icon: "tick",
  },
  {
    uniqueId: "B-25",
    title: "B",
    isParagraphStart: false,
    id: 25,
    text: "Wise (2018) mentions that some universities in United States provide napping stations or areas assigned for napping, from technologically advanced pods to beanbags to cots to recliners were provided.",
    annotation:
      "Explains what napping stations are. This would be better earlier in the paragraph.",
    icon: "light_bulb",
  },
  {
    uniqueId: "B-26",
    title: "B",
    isParagraphStart: false,
    id: 26,
    text: " Therefore, more universities can use the same concept and add napping stations around campus, potentially enabling students to focus during class.",
    annotation: "Summarises the main idea of the paragraph.",
    icon: "tick",
  },
  {
    uniqueId: "B-27",
    title: "B",
    isParagraphStart: true,
    id: 27,
    text: "In conclusion, a relatively poor GPA and lack of engagement in class are caused by sleep deprivation within undergraduate students. They may contemplate on consuming caffeine as a short-term solution to boost their attention, while universities could consider adding some napping stations or creating some designated areas for students to retrieve their energy, thus enhancing overall academic performance.",
    annotation:
      "Restates the problems and solutions; It would be better to include a concluding sentence.",
    icon: "light_bulb",
  },
  {
    uniqueId: "C-1",
    title: "C",
    isParagraphStart: true,
    id: 1,
    text: "The increasing reliance on social media among students has raised concerns about its potential negative impact on academic performance. ",
    annotation: "Gives background sentence to introduce the topic.",
    icon: "tick",
  },
  {
    uniqueId: "C-2",
    title: "C",
    isParagraphStart: false,
    id: 2,
    text: "As students often favour social media activities over academic tasks, their academic outcomes, including grades and concentration, may suffer. Overuse of social media could become a barrier to student’s academic success, leading to lost focus and reduced study time. For instance, a news report from The Cord News highlights that increased social media use correlates with a measurable decrease in academic performance, as valuable study time is often replaced by online engagement (Doolittle, 2023). ",
    annotation:
      "Explains the negative impact of social media. Includes citation to give support.",
    icon: "tick",
  },
  {
    uniqueId: "C-3",
    title: "C",
    isParagraphStart: false,
    id: 3,
    text: "This essay will examine the negative effects of excessive social media use on students' academic performance, focusing on reduced grades and concentration. It will also propose practical solutions to promote healthier digital habits.",
    annotation:
      "Provides an overview of the essay including main problems. Better to also include what solutions should be promoted.",
    icon: "light_bulb",
  },
  {
    uniqueId: "C-4",
    title: "C",
    isParagraphStart: true,
    id: 4,
    text: "Frequent use of social media among undergraduate students has been linked to lower grade point average. ",
    annotation:
      "Gives topic sentence with clear main idea ‘lowers grade point average’.",
    icon: "tick",
  },
  {
    uniqueId: "C-5",
    title: "C",
    isParagraphStart: false,
    id: 5,
    text: "Frequent use of social media among undergraduate students has been linked to lower grade point average. ",
    annotation:
      "Provides support for the main idea by citing an expert in the field.",
    icon: "tick",
  },
  {
    uniqueId: "C-6",
    title: "C",
    isParagraphStart: false,
    id: 6,
    text: "According to Kwok et al. (2021), social media overuse is strongly correlated with poor academic performance, reflected in a decline in grade point average. University students often perceive smartphones as a gadget for entertainment rather than a tool for academic pursuit. ",
    annotation: "Explains how social media lowers academic performance.",
    icon: "tick",
  },
  {
    uniqueId: "C-7",
    title: "C",
    isParagraphStart: false,
    id: 7,
    text: "They often prefer social media over academic activities, such as completing assignments and reading. More time spent on social media translates to less time on reading and completing assignments. ",
    annotation:
      "Better not repeat the point mentioned in the previous sentences.",
    icon: "light_bulb",
  },
  {
    uniqueId: "C-8",
    title: "C",
    isParagraphStart: false,
    id: 8,
    text: "Students often spend hours browsing social media, leaving less time for studying and working on course materials.",
    annotation: "",
    icon: "NA",
  },
  {
    uniqueId: "C-9",
    title: "C",
    isParagraphStart: false,
    id: 9,
    text: " Excessive social media use prevents students from fully committing to their academic work, ultimately affecting their grades and overall academic performance.",
    annotation: "Summarizes the main point of the paragraph.",
    icon: "tick",
  },
  {
    uniqueId: "C-10",
    title: "C",
    isParagraphStart: true,
    id: 10,
    text: "Excessive use of social media is a common issue among university students, but it can be reduced to some degree through various strategies. ",
    annotation: "Gives topic sentence with main idea.",
    icon: "tick",
  },
  {
    uniqueId: "C-11",
    title: "C",
    isParagraphStart: false,
    id: 11,
    text: "Yao and Wang (2023) suggest that students should devote their time to finding new interests and hobbies, such as joining student society and meeting different friends in the real world. University administration could implement policies to limit smartphone use during class. Engaging in physical activity has also been shown to significantly reduce smartphone overuse. To cope with stress and overuse of social media, students could also seek counselling from health professionals. Similarly, the National Institutes of Health (NIH, 2022) provides useful tips to counter the effects of online overuse among students. For instance, students are encouraged to take regular breaks from social media and engage in offline activities to help prevent digital fatigue. Additionally, students are motivated to connect with real people and find support from offline communities rather than relying solely on social media for comfort.",
    annotation:
      "Introduces and lists different strategies. The writer should support, explain or elaborate on these points more.",
    icon: "light_bulb",
  },
  {
    uniqueId: "C-12",
    title: "C",
    isParagraphStart: false,
    id: 12,
    text: " It is important to note that while social media platforms can provide connections, they should never replace in-person interaction, nor are they substitutes for real-life support.",
    annotation:
      "Better to clearly link this idea to the points made earlier in the paragraph.",
    icon: "light_bulb",
  },
  {
    uniqueId: "C-13",
    title: "C",
    isParagraphStart: false,
    id: 13,
    text: " If students are experiencing academic difficulties, they are encouraged to seek help from teachers or student counselling services on campus.",
    annotation:
      "Better for the final sentence to be a summary of the main points. The main point introduced here is not relevant to the points made in the paragraph. ",
    icon: "light_bulb",
  },
  {
    uniqueId: "C-14",
    title: "C",
    isParagraphStart: false,
    id: 14,
    text: "Social media overuse can diminish students' concentration on performing academic tasks.",
    annotation:
      "Gives a topic sentence with main idea. Better if the main idea were more sufficiently different from the main problem introduced in paragraph 2.",
    icon: "light_bulb",
  },
  {
    uniqueId: "C-15",
    title: "C",
    isParagraphStart: false,
    id: 15,
    text: " In a recent article, Jabeen et al. (2023) observe that students often switch between academic tasks and social media browsing. Hence, there is little chance left for students to immerse themselves in a high level of deep and uninterrupted learning. ",
    annotation:
      "Explains the idea introduced in the topic sentence with citation to provide credibility.",
    icon: "tick",
  },
  {
    uniqueId: "C-16",
    title: "C",
    isParagraphStart: false,
    id: 16,
    text: "Another observation mentioned in this article is the constant notifications and the sheer volume of social media content received by students, which has overwhelmed their cognitive processing capacity. This information overload interferes with their ability to focus on academic tasks, making it difficult to process useful information and concentrate effectively on academic studies.",
    annotation: "Elaborates and further explains main point.",
    icon: "tick",
  },
  {
    uniqueId: "C-17",
    title: "C",
    isParagraphStart: false,
    id: 17,
    text: " If social media overuse becomes a distraction, a student's ability to perform academically will be greatly hindered",
    annotation: "Summarizes the main point made in the paragraph.",
    icon: "tick",
  },
  {
    uniqueId: "C-18",
    title: "C",
    isParagraphStart: true,
    id: 18,
    text: "One approach to mitigating the distractions caused by frequent access to social media is to educate students to adopt a digital wellness mentality.",
    annotation: "Gives topic sentence with clear main idea ‘digital wellness’.",
    icon: "tick",
  },
  {
    uniqueId: "C-19",
    title: "C",
    isParagraphStart: false,
    id: 19,
    text: "Roffarello and Russis (2023) describe digital wellness as a combination of self-regulation strategies and lifestyle adjustments to help students become less reliant on social media activities. ",
    annotation:
      "Defines the main idea using a citation to add academic credibility and avoid plagiarism.",
    icon: "tick",
  },
  {
    uniqueId: "C-20",
    title: "C",
    isParagraphStart: false,
    id: 20,
    text: "One way to achieve this is to utilize screen time tracking apps such as “Apple's Screen Time“ or “Android's Digital Wellbeing” to monitor and manage time spent on social media and other non-essential online activities. The authors suggest that these tracking apps, with features such as usage statistics, time limit setting, and lock-out mechanisms, can encourage healthy social media use and prevent distractions. ",
    annotation: "Gives the first supporting point.",
    icon: "tick",
  },
  {
    uniqueId: "C-21",
    title: "C",
    isParagraphStart: false,
    id: 21,
    text: "To help students from getting sidetracked by constant feeds or notifications sent from social media platforms, the authors encourage students to maintain a purposeful mindset when engaging in online activities, rather than aimlessly scrolling through the web without a specific goal.",
    annotation: "Introduces a second supporting point.",
    icon: "tick",
  },
  {
    uniqueId: "C-22",
    title: "C",
    isParagraphStart: false,
    id: 22,
    text: " Another effective way to practice digital wellness is to spend time engaging in real-world experiences such as outdoor activities, social gatherings with friends and family, or pursuing new hobbies. These offline activities are especially beneficial in reducing reliance on excessive social media use. ",
    annotation:
      "Better to not repeat this point. It has already been made in paragraph 2. ",
    icon: "light_bulb",
  },
  {
    uniqueId: "C-23",
    title: "C",
    isParagraphStart: false,
    id: 23,
    text: 'An earlier news article published by the Massachusetts Institute of Technology, MindHandHeart (2020), proposes similar approaches to help students maintain a balanced relationship with social media. In particular, it proposes that instead of constantly checking social media platforms for new messages or photos, students should live in the moment, rather than reducing life to digital footprints only. "So capture that amazing sunset, but don\'t forget to enjoy it, too" (MindHandHeart, 2020). ',
    annotation:
      "Better to more clearly explain what ‘live in the moment’ means.",
    icon: "light_bulb",
  },
  {
    uniqueId: "C-24",
    title: "C",
    isParagraphStart: false,
    id: 24,
    text: "Overall, achieving digital wellness requires a proactive approach, and higher education institutions could play a significant role by providing students with resources, guidelines, and tools to foster a learning environment free from digital distractions.",
    annotation: "Summarizes by recommending action ",
    icon: "tick",
  },
  {
    uniqueId: "C-25",
    title: "C",
    isParagraphStart: true,
    id: 25,
    text: "In conclusion, excessive social media use poses significant challenges to the academic performance of undergraduate students, contributing to lower grades and reduced focus.",
    annotation:
      "Summarizes the problems. Better to delete the final part of the sentence ‘contributing to lower grades and reduced focus’. It as it is explained in sentence 2.",
    icon: "light_bulb",
  },
  {
    uniqueId: "C-26",
    title: "C",
    isParagraphStart: false,
    id: 26,
    text: " This essay examined two key issues: unsatisfactory grade point average due to time displacement and cognitive overload from constant social media engagement. ",
    annotation: "Restates main problems discussed in the essay.",
    icon: "tick",
  },
  {
    uniqueId: "C-27",
    title: "C",
    isParagraphStart: false,
    id: 27,
    text: "Proposed solutions include promoting digital wellness, encouraging physical and offline activities, and implementing self-regulation tools such as screen time tracking apps. ",
    annotation: "Restates the main solutions.",
    icon: "tick",
  },
  {
    uniqueId: "C-28",
    title: "C",
    isParagraphStart: false,
    id: 28,
    text: "These strategies can help students manage their social media habits more effectively. A balanced approach to digital consumption is essential, and institutions should support students in building healthier relationships with technology for academic success.",
    annotation:
      "Gives clear concluding sentences. Recommends the action proposed in the essay.",
    icon: "tick",
  },
  {
    uniqueId: "D-1",
    title: "D",
    isParagraphStart: true,
    id: 1,
    text: "Secondary students’ suicide has become frequent and concerning in Hong Kong. ",
    annotation: "Provides background to the topic.",
    icon: "tick",
  },
  {
    uniqueId: "D-2",
    title: "D",
    isParagraphStart: false,
    id: 2,
    text: "Hong Kong’s teenage suicide rate ranked the highest as a developed city among the world (SCMP, 2024a). ",
    annotation: "Shows how important the topic is.",
    icon: "tick",
  },
  {
    uniqueId: "D-3",
    title: "D",
    isParagraphStart: false,
    id: 3,
    text: "Parental pressure and bullying at schools could possibly be the main causes that lead to suicide among high school students. ",
    annotation: "Introduces the causes that will be discussed in the essay.",
    icon: "tick",
  },
  {
    uniqueId: "D-4",
    title: "D",
    isParagraphStart: false,
    id: 4,
    text: "To prevent suicidal occurrence, the involvement of family and emotional management are fundamental. ",
    annotation: "Outlines the solutions that will be discussed in the essay.",
    icon: "tick",
  },
  {
    uniqueId: "D-5",
    title: "D",
    isParagraphStart: false,
    id: 5,
    text: "This essay will explore two causes of middle school students’ suicide and suggest relevant solutions to address the problems.",
    annotation: "Gives the overview of the essay.",
    icon: "tick",
  },
  {
    uniqueId: "D-6",
    title: "D",
    isParagraphStart: true,
    id: 6,
    text: "Parental pressure may be a significant factor that induced the possibilities of adolescents’ suicide. ",
    annotation:
      "Gives the topic sentence introducing the main idea of the paragraph (parental pressure leads to suicides)",
    icon: "tick",
  },
  {
    uniqueId: "D-7",
    title: "D",
    isParagraphStart: false,
    id: 7,
    text: "Scholars (Conner et al., 2010; Phosaly et al., 2019, as cited in Haspolat & Yalçın, 2023) point out that excessively high parental expectation is an element of shaping enormous academic pressure.",
    annotation:
      "Provides citations to give evidence to support the writer’s main idea. ",
    icon: "tick",
  },
  {
    uniqueId: "D-8",
    title: "D",
    isParagraphStart: false,
    id: 8,
    text: "When parents establish high academic goals for their children, students could be forced to achieve distinction grades at school. Pressure is accompanied with negative effects such as anxiety, depression, exhaustion and suicide (Phosaly et al., 2019, as cited in Haspolat & Yalçın, 2023). This may possibly create intense stress for students, particularly when they worry disappointing their parents if they did not obtain distinction grades in the academy. ",
    annotation: "Explains the negative effects of parental pressure.",
    icon: "tick",
  },
  {
    uniqueId: "D-9",
    title: "D",
    isParagraphStart: false,
    id: 9,
    text: "The study investigates Hong Kong’s youth suicide attempts, which are greatly associated with family relationships (Low, 2021). Teenagers could psychologically have depressive and anxious symptoms because of unsatisfactory or failure in examination results. Meanwhile, parents may continuously aim for high academic performances without encouraging and neglect their children’s mental health.",
    annotation:
      "Elaborates further. It would be better to avoid repeating ideas mentioned before.",
    icon: "light_bulb",
  },
  {
    uniqueId: "D-10",
    title: "D",
    isParagraphStart: false,
    id: 10,
    text: "Juveniles may not be able to alleviate stresses and are probably having suicidal thoughts that induce suicide occurrences. ",
    annotation: "",
    icon: "NA",
  },
  {
    uniqueId: "D-11",
    title: "D",
    isParagraphStart: false,
    id: 11,
    text: "Thus, family stresses could increase the possibility of adolescents’ suicide.",
    annotation: "Summarizes the main point. Paraphrases the topic sentence.",
    icon: "tick",
  },
  {
    uniqueId: "D-12",
    title: "D",
    isParagraphStart: true,
    id: 12,
    text: "Regarding the parental stress towards youths, the solution should concentrate on transferring parental pressure into parental warmth for adolescents. ",
    annotation:
      "Gives the topic sentence (Parental warmth can reduce this stress).",
    icon: "tick",
  },
  {
    uniqueId: "D-13",
    title: "D",
    isParagraphStart: false,
    id: 13,
    text: "Warmth is an incentive system which encourages positive behaviour in accelerating children to recognize parents' actions (Bandura, 1969; Macdonald, 1992, as cited in Padilla-Walker at al., 2016), follow parents’ requirements and shared goals (Kuczynski & Hildebrandt, 1997, as cited in Padilla-Walker at al., 2016). ",
    annotation:
      "Defines the key idea ‘parental warmth’ with clear citation to give credibility.",
    icon: "tick",
  },
  {
    uniqueId: "D-14",
    title: "D",
    isParagraphStart: false,
    id: 14,
    text: "As mentioned above, parents’ expectations would be repeatedly pursuing for exceptional performance. If the result is dissatisfying which accumulates students’ stress with mental disorders and suicide risk; it could become a vicious cycle. ",
    annotation: "",
    icon: "NA",
  },
  {
    uniqueId: "D-15",
    title: "D",
    isParagraphStart: false,
    id: 15,
    text: "Oppositely, when parents show kindness and support instead of strictness to their children in educating, students may feel tolerated and relax on their learning paths. ",
    annotation: "Explains how parental warmth is beneficial.",
    icon: "tick",
  },
  {
    uniqueId: "D-16",
    title: "D",
    isParagraphStart: false,
    id: 16,
    text: "Quach et al. (2015) justify that greater parental warmth minimizes a certain extent of correlation between youths’ emotional distress and parental pressures. ",
    annotation: "Cites a source to support the previous sentence.",
    icon: "tick",
  },
  {
    uniqueId: "D-17",
    title: "D",
    isParagraphStart: false,
    id: 17,
    text: "Warmth parenting education would enhance the communications and interactions of both sides thereby reducing negative emotional symptoms and suicidal ideas in youngsters. ",
    annotation: "",
    icon: "NA",
  },
  {
    uniqueId: "D-18",
    title: "D",
    isParagraphStart: false,
    id: 18,
    text: "Also, ",
    annotation:
      "Better not use 'also' at the start of the sentence. 'In addition' or 'Furthermore' would be more suitable.",
    icon: "light_bulb",
  },
  {
    uniqueId: "D-19",
    title: "D",
    isParagraphStart: false,
    id: 19,
    text: "Zimmer-Gemback et al. (2023) justify that cordial parental associations are beneficial for teenagers’ current and future dependencies on additional engagement and little engagement method on parents of dealing with academic pressures. Parents praising and showing understandings to adolescents could be advantageous to lessen the mental stresses’ burden in order to prevent the adolescent’s suicide. ",
    annotation:
      "Continues to elaborate. However, it is not clear that these points differ from those made earlier in the paragraph. Better to give a statistic, quotation or comparison of Hong Kong with a society where parental warmth is common.",
    icon: "light_bulb",
  },
  {
    uniqueId: "D-20",
    title: "D",
    isParagraphStart: false,
    id: 20,
    text: "The incremental care and support from parents could effectively convert pressure into encouragement for secondary school students to cope with the suicidal intention.",
    annotation: "Summarizes the main points made in the paragraph.",
    icon: "tick",
  },
  {
    uniqueId: "D-21",
    title: "D",
    isParagraphStart: true,
    id: 21,
    text: "Another possible reason of secondary school students’ suicide is bullying at school, which leads to incidence of youth suicide. ",
    annotation:
      "Gives a topic sentence to introduce the main idea ‘bullying’. However, would be better to also include the second main point 'social isolation'.",
    icon: "light_bulb",
  },
  {
    uniqueId: "D-22",
    title: "D",
    isParagraphStart: false,
    id: 22,
    text: "High school students encountered problems of school bullying and interpersonal relationships that might raise their chances of suicidal tendencies (SCMP, 2024b). ",
    annotation:
      "Provides a citation to support the claim made in the topic sentence",
    icon: "tick",
  },
  {
    uniqueId: "D-23",
    title: "D",
    isParagraphStart: false,
    id: 23,
    text: "The feeling of isolation and loneliness may affect students’ mental health being, these impacts could evolve into emotional disorders and suicidal ideation. According to the research study, victims generally distributed the highest pressure scores to socializing and their suicide thoughts were recorded substantially high (Kwan et al., 2022). ",
    annotation:
      "Explains how isolation causes suicidal feelings and cites a study to support the claim.",
    icon: "tick",
  },
  {
    uniqueId: "D-24",
    title: "D",
    isParagraphStart: false,
    id: 24,
    text: "The statistic highlights teenagers who had difficulties socializing and interacting could tend to commit suicide.",
    annotation: "Explains the citation given in the previous sentence.",
    icon: "tick",
  },
  {
    uniqueId: "D-25",
    title: "D",
    isParagraphStart: false,
    id: 25,
    text: "Some of the victims might attempt suicide eventually as they no longer endure from being bullied and negative psychological illnesses.",
    annotation: "",
    icon: "NA",
  },
  {
    uniqueId: "D-26",
    title: "D",
    isParagraphStart: false,
    id: 26,
    text: " Hasan et al. (2021) found that about a quarter of 280,076 middle school students sample size reflecting “bullying and suicidal ideation was mediated by loneliness” (p.1). ",
    annotation:
      "Provides statistics and a quotation to support the main idea of the paragraph.",
    icon: "tick",
  },
  {
    uniqueId: "D-27",
    title: "D",
    isParagraphStart: false,
    id: 27,
    text: "Hence, secondary school students who struggle in peer relationships at school with emotional distresses may probably result in suicide incidents.",
    annotation: "Summarizes the main points made in the paragraph.",
    icon: "tick",
  },
  {
    uniqueId: "D-28",
    title: "D",
    isParagraphStart: true,
    id: 28,
    text: "The possible solution to preventing middle school students’ suicides from school bullying would be educating emotional management. ",
    annotation: "Gives a topic sentence with the main idea of the paragraph.",
    icon: "tick",
  },
  {
    uniqueId: "D-29",
    title: "D",
    isParagraphStart: false,
    id: 29,
    text: "Schools play a significant role in precautionary measures of students’ suicides; preventing bullying by guiding youths’ emotional management is necessary, for examples, emotional intelligence (EI) and social and emotional learning (SEL).",
    annotation: "Defines key point ‘emotional management’.",
    icon: "tick",
  },
  {
    uniqueId: "D-30",
    title: "D",
    isParagraphStart: false,
    id: 30,
    text: "Rey et al. (2019) claimed that EI is considered a personal ‘asset’ that may protect youngsters’ offenses from the effects of bullying and reduce the detrimental emotional influences of adversity on mental health. The Education Bureau could increase a new subject ‘Personal Well-Being Development’ for all the secondary institutions, targeting coaching students’ EI in assisting them to deal with bullying and peer stress, for instance, resolving disputes. This subject might help decrease bullying occurrence and increase high school students’ psychological resilience.",
    annotation: "Defines EI and explains how it could reduce suicides.",
    icon: "tick",
  },
  {
    uniqueId: "D-31",
    title: "D",
    isParagraphStart: false,
    id: 31,
    text: " On the other hand, SEL concentrates on cultivating positive, complete development of children by emphasizing behavioural control to develop psychological wellness (Divecha & Brackett, 2019). Divecha and Brackett (2019) also indicated that SEL encourage students to learn positive actions for coping with aggressiveness, assertion of power and bullying. Implementing EI training and SEL programs at schools not only allows juveniles to strengthen their attitudes in building up mental health, but also learning appropriate behaviours, such as seeking help rather than attempt suicide, when facing disruptive actions and bullying.",
    annotation:
      "Defines SEL and explains how it could reduce suicides. Citations given to add credibility and avoid plagiarism.",
    icon: "tick",
  },
  {
    uniqueId: "D-32",
    title: "D",
    isParagraphStart: false,
    id: 32,
    text: "  Through improving emotion quotient and stress management, it could be efficient to reduce the chances of high school students’ suicide.",
    annotation: "Summarizes the main points made in the paragraph.",
    icon: "tick",
  },
  {
    uniqueId: "D-33",
    title: "D",
    isParagraphStart: true,
    id: 33,
    text: "In conclusion, parental tension and school bullying might predominantly be the causes that lead to Hong Kong’s secondary school students’ suicide. ",
    annotation: "Summarizes paragraph 2 and 4. ",
    icon: "tick",
  },
  {
    uniqueId: "D-34",
    title: "D",
    isParagraphStart: false,
    id: 34,
    text: "Changing parents’ stresses into parent intimacy and directing teenagers’ emotional management would be contributive in preventing suicide issues.",
    annotation:
      "Summarizes paragraph 3 and 4. It would be better to end with a final concluding sentence.",
    icon: "light_bulb",
  },
  {
    uniqueId: "E-1",
    title: "E",
    isParagraphStart: true,
    id: 1,
    text: "Workplace burnout has been an issue across industries for a long time.",
    annotation:
      "Provides a general background to the topic. The citation used follows the IEEE system, which is often used in computer science and electronic engineering.",
    icon: "tick",
  },
  {
    uniqueId: "E-2",
    title: "E",
    isParagraphStart: false,
    id: 2,
    text: "However, the problem has become aggravating recently [1]. It is a crucial topic that affects the health of staff and company productivity.",
    annotation: "Defines ‘workplace burnout’.",
    icon: "tick",
  },
  {
    uniqueId: "E-3",
    title: "E",
    isParagraphStart: false,
    id: 3,
    text: "Job burnout can cause employees emotional and physical exhaustion, low job satisfaction, and even depression and anxiety [1]. It will harm competitiveness and organizational productivity and cause a large amount of financial loss.",
    annotation: "Explains why ‘burnout’ is a problem.",
    icon: "tick",
  },
  {
    uniqueId: "E-4",
    title: "E",
    isParagraphStart: false,
    id: 4,
    text: "The World Health Organization (WHO) [2] estimates that every year, 12 billion workdays are lost, resulting in $1 trillion in productivity losses globally due to depression and apprehension. How to handle this matter appears to be a sine qua non for institutions to consider. The causes of workplace burnout and the recommendations of preventive measures will be discussed in the essay. ",
    annotation:
      "Outlines the causes and recommendations. It would be better to also mention the causes and recommendations.",
    icon: "tick",
  },
  {
    uniqueId: "E-5",
    title: "E",
    isParagraphStart: true,
    id: 5,
    text: "One of the major causes of workforce burnout might be a myriad of workload.",
    annotation:
      "Gives a clear topic sentence containing the main idea of the paragraph (workload causes stress and burnout).",
    icon: "tick",
  },
  {
    uniqueId: "E-6",
    title: "E",
    isParagraphStart: false,
    id: 6,
    text: "All the fatigue and exhaustion caused by an overwhelming workload will result in burnout.",
    annotation:
      "Explains the main idea in the previous sentence. Better to give a citation or statistic to support the main idea of the paragraph.",
    icon: "tick",
  },
  {
    uniqueId: "E-7",
    title: "E",
    isParagraphStart: false,
    id: 7,
    text: "There are several reasons for the workload. ",
    annotation: "",
    icon: "NA",
  },
  {
    uniqueId: "E-8",
    title: "E",
    isParagraphStart: false,
    id: 8,
    text: "Some institutions might not have enough workforce to cope with the heavy workload or ineffective time management yields many undone tasks, or even the coming peak season enhances the demand for the workforce.",
    annotation: "",
    icon: "NA",
  },
  {
    uniqueId: "E-9",
    title: "E",
    isParagraphStart: false,
    id: 9,
    text: "These influences result in excessive workload for the personnel, damaging their emotional health. ",
    annotation: "",
    icon: "NA",
  },
  {
    uniqueId: "E-10",
    title: "E",
    isParagraphStart: false,
    id: 10,
    text: "A study [3] indicates workload is proportional to role stress. When an employee’s workload increases, it is also followed by stress.",
    annotation: "",
    icon: "NA",
  },
  {
    uniqueId: "E-11",
    title: "E",
    isParagraphStart: false,
    id: 11,
    text: "The higher the feeling of increased workload they feel, the less productive they become.",
    annotation: "",
    icon: "NA",
  },
  {
    uniqueId: "E-12",
    title: "E",
    isParagraphStart: false,
    id: 12,
    text: "It gives rise to higher role stress for staff.",
    annotation: "",
    icon: "NA",
  },
  {
    uniqueId: "E-13",
    title: "E",
    isParagraphStart: false,
    id: 13,
    text: "The excessive workload would lead to the burnout of employees, decreasing their performance at work and making them feel the growing amount of stress.",
    annotation: "",
    icon: "NA",
  },
  {
    uniqueId: "E-14",
    title: "E",
    isParagraphStart: false,
    id: 14,
    text: "Role stress is also proportional to burnout. The more stress employees feel, the more burnout will be [3].",
    annotation:
      "Contains the same idea in these sentences. It would be better to provide support for this by giving facts, examples or a quotation from the study cited.",
    icon: "light_bulb",
  },
  {
    uniqueId: "E-15",
    title: "E",
    isParagraphStart: false,
    id: 15,
    text: "This means excessive workload will cause increasing role stress and eventually contribute to burnout. Workloads have a critical impact on workplace burnout.",
    annotation: "Summarizes the paragraph.",
    icon: "tick",
  },
  {
    uniqueId: "E-16",
    title: "E",
    isParagraphStart: true,
    id: 16,
    text: "In addition to a myriad of workloads and stress, another cause is that employees may not gain enough power and control over their work.",
    annotation:
      "Gives a clear topic sentence containing the main idea of the paragraph (lack of power and control) and a link to the previous paragreaph (In addition to...).",
    icon: "tick",
  },
  {
    uniqueId: "E-17",
    title: "E",
    isParagraphStart: false,
    id: 17,
    text: "When employees cannot make their own decisions on their job or project, they might feel low involvement and unmotivated. The feeling of insufficient control over the company task could also lead to frustration and resentment. ",
    annotation: "Explains the main idea.",
    icon: "tick",
  },
  {
    uniqueId: "E-18",
    title: "E",
    isParagraphStart: false,
    id: 18,
    text: "These factors cause burnout and low job satisfaction. ",
    annotation: "Explains how lack of power causes burnout.",
    icon: "tick",
  },
  {
    uniqueId: "E-19",
    title: "E",
    isParagraphStart: false,
    id: 19,
    text: "Edú-Valsania et al. [4] pointed out that lack of clout and autonomy are the vital origins of burnout. ",
    annotation:
      "Cites a paper to give credibility and support to the argument.",
    icon: "tick",
  },
  {
    uniqueId: "E-20",
    title: "E",
    isParagraphStart: false,
    id: 20,
    text: "The restrictions on tasks and being unable to affect the mission decisions are proportional to a high level of workplace burnout. It emphasizes the importance of empowerment for employees. The staff who are provided with low empowerment tend to be frustrated and experience more negative emotions, leading to burnout.",
    annotation: "Elaborates on how a lack of power leads to burn out.",
    icon: "tick",
  },
  {
    uniqueId: "E-21",
    title: "E",
    isParagraphStart: false,
    id: 21,
    text: "Improper empowerment will give staff a sense of insufficient control that brings negative feelings to workers causing job burnout.",
    annotation: "Summarizes the main idea in the paragraph.",
    icon: "tick",
  },
  {
    uniqueId: "E-22",
    title: "E",
    isParagraphStart: true,
    id: 22,
    text: "To address the issue of workplace burnout caused by task overload, employers could organize some physical activities (PA) that have been scientifically proven to reduce workplace burnout effectively.",
    annotation:
      "Give a clear topic sentence that links to the previous paragraph and provides the main idea of this paragraph.",
    icon: "tick",
  },
  {
    uniqueId: "E-23",
    title: "E",
    isParagraphStart: false,
    id: 23,
    text: "Sports are a beneficial way to relax and help people build a strong and fit body to handle all the challenges. There are a variety of merits of sports such as improving sleep that can bring a positive mood reducing fatigue as a refreshment and improving memory and concentration to boost efficiency and effectiveness.",
    annotation:
      "Explains how physical activity (main idea in the topic sentence) helps.",
    icon: "tick",
  },
  {
    uniqueId: "E-24",
    title: "E",
    isParagraphStart: false,
    id: 24,
    text: " A study [5] found that moderate-intensity aerobic physical activity (MPA) such as brisk walking and swimming, which is at least ten minutes each time can directly and thoroughly tackle the symptoms of occupational burnout. ",
    annotation: "Provides support by citing an academic paper.",
    icon: "tick",
  },
  {
    uniqueId: "E-25",
    title: "E",
    isParagraphStart: false,
    id: 25,
    text: "Vigorous-intensity aerobic physical activity (VPA) can mitigate the negative impact of burnout symptoms. MPA can be a preventive measure while VPA can be part of treatment to cope with the overwhelming stress and burnout. It is ideal to regularly launch some dynamic exercise programs, which combine both MPA and VPA for the crew. Both prevention and mitigation can be simultaneously met to solve employee fatigue. ",
    annotation:
      "Better to have a citation for this detailed elaboration on types of exercises.",
    icon: "light_bulb",
  },
  {
    uniqueId: "E-26",
    title: "E",
    isParagraphStart: false,
    id: 26,
    text: "PA is a powerful way to resolve occupational burnout.",
    annotation: "Gives a clear summary of the main idea in the paragraph.",
    icon: "tick",
  },
  {
    uniqueId: "E-27",
    title: "E",
    isParagraphStart: true,
    id: 27,
    text: "To resolve insufficient power and control problems over employees, it is useful that organizations provide more authority and chances to make decisions for staff. ",
    annotation:
      "Clear topic sentence that links to previous paragraph and provides the main idea of this paragraph: give workers more authority.",
    icon: "tick",
  },
  {
    uniqueId: "E-28",
    title: "E",
    isParagraphStart: false,
    id: 28,
    text: "Allowing employees to participate in making decisions is a de facto and helpful way to decrease burnout. It can significantly enhance the transparency of communication and grant a sense of control to staff instead of restricting personnel’s actions and voice, which contributes to a feeling of “cynicism”. By doing this, workers will feel more committed and valued and consider the enterprise fair. Ultimately, there are much fewer possibilities of burnout [6].",
    annotation:
      "Explains how the main idea introduced in the topic sentence would benefit workers.",
    icon: "tick",
  },
  {
    uniqueId: "E-29",
    title: "E",
    isParagraphStart: false,
    id: 29,
    text: " Çelik et al. [7] found that “structural empowerment” and “psychological empowerment” can reduce the level of occupational burnout. Staff empowerment is inversely proportional to burnout. ",
    annotation: "Cites a source to support his argument.",
    icon: "tick",
  },
  {
    uniqueId: "E-30",
    title: "E",
    isParagraphStart: false,
    id: 30,
    text: "More autonomy at work can lower burnout. It proves that it is an effective measure to grant more power to employees to lower their burnout.",
    annotation:
      "Sentence 8 Gives a clear summary of the main idea in the paragraph.",
    icon: "tick",
  },
  {
    uniqueId: "E-31",
    title: "E",
    isParagraphStart: true,
    id: 31,
    text: "This essay has analysed the causes of workplace burnout and provided two applicable preventions to address the issues. ",
    annotation: "Paraphrases the essay title to summarize the essay.",
    icon: "tick",
  },
  {
    uniqueId: "E-32",
    title: "E",
    isParagraphStart: false,
    id: 32,
    text: "To solve the burnout resulting from considerable workload, it is suggested that corporations regularly launch some physical activity programs including MPA and VPA that have been scientifically proven to mitigate and prevent burnout adequately.",
    annotation: "Summarises paragraph 2 and 4.",
    icon: "tick",
  },
  {
    uniqueId: "E-33",
    title: "E",
    isParagraphStart: false,
    id: 33,
    text: " Insufficient autonomy and control offered to employees is also an essential issue that cannot be overemphasized. Enterprises should give more self-determination and control to staff to reduce the negative emotions such as hard feelings and resentment that lead to job burnout.",
    annotation: "Summarises paragraph 3 and 5.",
    icon: "tick",
  },
  {
    uniqueId: "E-34",
    title: "E",
    isParagraphStart: false,
    id: 34,
    text: "By using these preventive measures, workplace burnout can be productively addressed.",
    annotation: "Summarizes main point.",
    icon: "tick",
  },
  {
    uniqueId: "E-35",
    title: "E",
    isParagraphStart: false,
    id: 35,
    text: "Organizations should take action to cope with burnout as soon as possible for higher productivity.",
    annotation: "Gives closing comments recommending action.",
    icon: "tick",
  },
];
