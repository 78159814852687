// hooks/useExercises.js
import { useState, useEffect } from "react";
import axios from "axios";

const useExercises = () => {
  const [exercises, setExercises] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  const [error, setError] = useState(null);
  const apiEndpoint = process.env.REACT_APP_SERVER_ENDPOINT;

  useEffect(() => {
    const fetchExercises = async () => {
      try {
        const response = await axios.get(`${apiEndpoint}/exercises-metadata`);
        setExercises(response.data.exercises_metadata);
        setIsFetching(false);
      } catch (error) {
        console.error("Error fetching exercises:", error);
        setError(error.message);
        setIsFetching(false);
      }
    };

    fetchExercises();
  }, []);
  return { exercises, isFetching, error };
};

export default useExercises;
