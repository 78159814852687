import { useState, useEffect } from "react";
import axios from "axios";

const useUserScores = (user, apiEndpoint) => {
  const [scores, setScores] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [hasFetched, setHasFetched] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!user) {
      setScores(null);
      return;
    }

    // Fetch fresh scores in the background
    const fetchScores = async () => {
      setIsFetching(true);
      try {
        const response = await axios.get(`${apiEndpoint}/receiveAllScores`, {
          params: { user_email: user.email.toLowerCase() },
        });
        const newScores = response.data;
        setScores(newScores);
      } catch (err) {
        console.error("Failed to fetch scores:", err);
        setError(err);
      } finally {
        setIsFetching(false);
        setHasFetched(true);
      }
    };

    fetchScores();
  }, [user, apiEndpoint]);

  return { scores, isFetching, hasFetched, error };
};

export default useUserScores;
