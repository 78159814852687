export interface Essay {
  id: string;
  fullTitle: string;
  // You can add more metadata properties such as overallComments, guidelines, etc.
}

export const essays: Essay[] = [
  {
    id: "A",
    fullTitle:
      "Identify the causes of burnout in the workplace and propose improvement measures.",
  },
  {
    id: "B",
    fullTitle:
      "Analyse the adverse effects of sleep deprivation on academic performance among university students.",
  },
  {
    id: "C",
    fullTitle:
      "Analyse the negative impact of frequent use of social media on academic performance of undergraduate students and suggest ways to mitigate these negative effects.",
  },
  {
    id: "D",
    fullTitle:
      "Identify the causes of secondary students’ suicide in Hong Kong and provide solutions to prevent it.",
  },
  {
    id: "E",
    fullTitle:
      "Identify the causes of workplace burnout and recommend measures to prevent it, while still maintaining high levels of productivity and job satisfaction.",
  },
];
