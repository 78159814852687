import React, { useState, useEffect, ChangeEvent, FormEvent } from "react";
import "./SignUpWindow.css";
import { useNavigate } from "react-router-dom";
import InputField from "./InputField";
// Define the shape of the user object
interface User {
  email: string;
  name: string;
}

// Define the props for SignUpWindow component
interface SignUpWindowProps {
  onLogin: () => void;
  setUser: (user: User) => void;
  isAuthenticated: boolean;
}

const SignUpWindow: React.FC<SignUpWindowProps> = ({
  onLogin,
  setUser,
  isAuthenticated,
}) => {
  const navigate = useNavigate();

  // Define state variables with appropriate types
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [userName, setUserName] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [isValidSignUp, setIsValidSignUp] = useState<boolean | undefined>(
    undefined,
  );
  const [signUpFailedMessage, setSignUpFailedMessage] = useState<
    string | undefined
  >(undefined);
  const [countdown, setCountdown] = useState<number>(3); // Countdown starting from 3 seconds

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/dashboard");
    }
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isValidSignUp && countdown > 0) {
      timer = setTimeout(() => setCountdown((prev) => prev - 1), 1000);
    } else if (isValidSignUp && countdown === 0) {
      navigate("/dashboard");
    }
    return () => clearTimeout(timer); // Cleanup the timer
  }, [isValidSignUp, countdown, navigate]);

  // Handle input changes with proper typing
  const handleUserNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUserName(e.target.value.trim());
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value);
  };

  // Handle form submission with proper typing
  const handleSignUp = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Prevent the default form submit action

    try {
      // Send a POST request to the server

      const response = await fetch(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/register`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_name: userName.toLowerCase(),
            user_email: email,
            password: password,
            confirm_password: confirmPassword,
          }), // Adjust these fields based on your backend expectations
        },
      );

      const data = await response.json();

      if (response.status === 200 || response.status === 201) {
        onLogin();
        setUser({ email: data.user_email, name: data.user_name });
        setIsValidSignUp(true);
        // Countdown is already handling navigation in useEffect
      } else {
        setIsValidSignUp(false);
        setSignUpFailedMessage(data.message);
        // Optionally, you can handle more specific error messages here
      }
    } catch (error) {
      setIsValidSignUp(false);
      setSignUpFailedMessage("An unexpected error occurred. Please try again.");
      console.error("SignUp Error:", error);
    }
  };

  return (
    <div className="signup-div grow justify-center">
      {!isValidSignUp && signUpFailedMessage && (
        <div
          className="display-login-reject mb-4 border-l-4 border-red-500 bg-red-100 p-4 text-red-700"
          role="alert"
        >
          <h1 className="text-lg font-semibold">{signUpFailedMessage}</h1>
        </div>
      )}
      {isValidSignUp && (
        <div
          className="mb-4 rounded border-l-4 border-green-500 bg-green-100 p-4 text-green-700 shadow-md"
          role="alert"
        >
          <h1 className="text-lg font-semibold">
            Sign Up successful! <br />
            Directing you to the dashboard in {countdown} seconds...
          </h1>
        </div>
      )}
      <div className="mb-8">
        <h1 className="text-center text-2xl font-bold text-gray-800">
          Sign Up
        </h1>
      </div>
      <form className="form-div" onSubmit={handleSignUp}>
        <label htmlFor="name">Name:</label>
        <InputField
          type="text"
          id="name"
          name="name"
          value={userName}
          onChange={handleUserNameChange}
        />
        <label htmlFor="email">Email:</label>
        <InputField
          type="email"
          id="email"
          name="email"
          value={email}
          onChange={handleEmailChange}
        />
        <label htmlFor="password">Password:</label>
        <InputField
          type="password"
          id="password"
          name="password"
          value={password}
          onChange={handlePasswordChange}
        />
        <label htmlFor="confirm-password">Confirm Password:</label>
        <InputField
          type="password"
          id="confirm-password"
          name="confirm-password"
          value={confirmPassword}
          onChange={handleConfirmPasswordChange}
        />
        <button
          type="submit"
          className="rounded bg-blue-500 px-4 py-2 font-bold text-white shadow-lg transition duration-300 hover:bg-blue-700 hover:shadow-xl"
        >
          Sign Up
        </button>
      </form>
    </div>
  );
};

export default SignUpWindow;
