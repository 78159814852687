import { useState, useEffect, createContext } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Navigate } from "react-router-dom";
import "./App.css";
import MCExercise from "./components/MCExercise";
import Navigation from "./components/Navigation";
import Dashboard from "./components/Dashboard";
import Exercises from "./components/Exercises";
import RejectAccess from "./components/RejectAccess";
import Leaderboard from "./components/Leaderboard";
import SignIn from "./components/SignIn";
import SignUp from "./components/SignUp";
import HomePage from "./components/HomePage";
import PrivateRoute from "./components/PrivateRoute";
import ScorePage from "./components/ScorePage";
import { LogoutContext } from "./components/LogoutContext";
import { ThemeProvider } from "./context/ThemeContext";
import axios from "axios";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import Regex from "./components/Regex";
import Dictionary from "./components/Dictionary";
import ProfileSection from "./components/ProfileSection";
import ScoresSection from "./components/ScoresSection";
import TextToSpeech from "./components/TextToSpeech";
import Annotate from "./components/Annotate";
export const UserContext = createContext(null);

const App = () => {
  const [isFetching, setIsFetching] = useState(true);
  const [user, setUser] = useState(null);
  const [exercise_titles, setExerciseTitles] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userPermissions, setUserPermissions] = useState({
    taggerAccess: false,
  });
  const [isAuthorized, setIsAuthorized] = useState(null); // can be true or false

  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  const handleLogout = async () => {
    try {
      setIsAuthenticated(false);
      await axios.post(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/logout`,
        {},
        {
          withCredentials: true,
        },
      );
      setUser({ email: "", name: "" });
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  useEffect(() => {
    const fetchExerciseTitles = async () => {
      try {
        // Fetch titles for exercises

        const titlesResponse = await axios.get(
          `${process.env.REACT_APP_SERVER_ENDPOINT}/titles`,
        );
        const uniqueTitles = titlesResponse.data.uniqueTitles;
        const allTitles = [];
        // Add uniqueTitles
        for (const title of uniqueTitles) {
          if (!allTitles.includes(title)) {
            allTitles.push(title);
          }
        }

        setExerciseTitles(uniqueTitles);
      } catch (error) {
        console.error("Error fetching exercise titles:", error);
      }
    };
    fetchExerciseTitles();
  }, []);

  useEffect(() => {
    const checkUserAuth = async () => {
      setIsFetching(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_ENDPOINT}/me`,
          { withCredentials: true }, // Important: allow cookies in requests
        );
        setIsAuthenticated(true);
        if (response.data.user_email && response.data.user_name) {
          setUser({
            email: response.data.user_email,
            name: response.data.user_name,
          });
          setIsAuthenticated(true);
        } else {
          setUser(null);
          setIsAuthenticated(false);
        }
      } catch (error) {
        setUser(null);
        setIsAuthenticated(false);
        handleLogout();
      } finally {
        setIsFetching(false);
      }
    };
    checkUserAuth();
  }, []);

  return (
    <ThemeProvider>
      <LogoutContext.Provider value={handleLogout}>
        <UserContext.Provider
          value={{
            user,
            setUser,
            isAuthenticated,
            setIsAuthenticated,
            isFetching,
            userPermissions,
            setUserPermissions,
            setIsAuthorized,
            isAuthorized,
          }}
        >
          <Router>
            <div className="flex min-h-screen flex-col bg-gray-100 text-gray-900 dark:bg-gray-900 dark:text-gray-100">
              <Navigation
                onLogin={handleLogin}
                onLogout={handleLogout}
                setUser={setUser}
              />
              <Routes>
                <Route
                  path="/regex"
                  element={
                    // <PrivateRoute requireSpecificAccess={true}>
                    <Regex />
                    // </PrivateRoute>
                  }
                />
                <Route path="/" element={<HomePage />} />
                <Route path="/annotate" element={<Annotate />} />
                <Route path="/dictionary" element={<Dictionary />} />

                <Route
                  path="/dashboard"
                  element={
                    <PrivateRoute>
                      <Dashboard />
                    </PrivateRoute>
                  }
                >
                  <Route index element={<ProfileSection />} />
                  <Route path="profile" element={<ProfileSection />} />
                  <Route path="scores" element={<ScoresSection />} />
                </Route>
                <Route
                  path="/leaderboard"
                  element={<Leaderboard exerciseTitles={exercise_titles} />}
                />

                <Route
                  path="/leaderboard"
                  element={<Leaderboard exerciseTitles={exercise_titles} />}
                />
                {exercise_titles.map((title) => (
                  <Route
                    key={title}
                    path={`/${title}`}
                    element={
                      <PrivateRoute>
                        {" "}
                        <MCExercise exerciseTitle={title} />{" "}
                      </PrivateRoute>
                    }
                  />
                ))}
                <Route
                  path="/score"
                  element={
                    <PrivateRoute>
                      <ScorePage />{" "}
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/sign-in"
                  element={
                    !isAuthenticated ? (
                      <SignIn onLogin={handleLogin} setUser={setUser} />
                    ) : (
                      <Navigate to="/dashboard" replace />
                    )
                  }
                />
                <Route path="/tts" element={<TextToSpeech />} />
                <Route
                  path="/sign-up"
                  element={
                    <SignUp
                      onLogin={handleLogin}
                      setUser={setUser}
                      isAuthenticated={isAuthenticated}
                    />
                  }
                />
                <Route path="/exercises" element={<Exercises />} />
                <Route
                  path="/reject-access"
                  element={
                    <RejectAccess setIsAuthenticated={setIsAuthenticated} />
                  }
                />
                <Route
                  path="/reset-password/:resetToken"
                  element={<ResetPassword />}
                />
                <Route path="/forgot-password" element={<ForgotPassword />} />
              </Routes>
            </div>
          </Router>
        </UserContext.Provider>
      </LogoutContext.Provider>
    </ThemeProvider>
  );
};

export default App;
