import React, { useState, useEffect } from "react";
import axios from "axios";

const Regex = () => {
  const [data, setData] = useState({
    regex: "",
    testcase: "",
    annotatedTestcase: "",
  });
  const [inputRegex, setInputRegex] = useState("");
  const [testcase, setTestcase] = useState("");

  // Define the Penn Treebank tag set with descriptions
  const tagLegend = [
    { tag: "CC", description: "Coordinating conjunction" },
    { tag: "CD", description: "Cardinal number" },
    { tag: "DT", description: "Determiner" },
    { tag: "EX", description: "Existential there" },
    { tag: "FW", description: "Foreign word" },
    { tag: "IN", description: "Preposition or subordinating conjunction" },
    { tag: "JJ", description: "Adjective" },
    { tag: "JJR", description: "Adjective, comparative" },
    { tag: "JJS", description: "Adjective, superlative" },
    { tag: "LS", description: "List item marker" },
    { tag: "MD", description: "Modal" },
    { tag: "NN", description: "Noun, singular or mass" },
    { tag: "NNS", description: "Noun, plural" },
    { tag: "NNP", description: "Proper noun, singular" },
    { tag: "NNPS", description: "Proper noun, plural" },
    { tag: "PDT", description: "Predeterminer" },
    { tag: "POS", description: "Possessive ending" },
    { tag: "PRP", description: "Personal pronoun" },
    { tag: "PRP$", description: "Possessive pronoun" },
    { tag: "RB", description: "Adverb" },
    { tag: "RBR", description: "Adverb, comparative" },
    { tag: "RBS", description: "Adverb, superlative" },
    { tag: "RP", description: "Particle" },
    { tag: "SYM", description: "Symbol" },
    { tag: "TO", description: "to" },
    { tag: "UH", description: "Interjection" },
    { tag: "VB", description: "Verb, base form" },
    { tag: "VBD", description: "Verb, past tense" },
    { tag: "VBG", description: "Verb, gerund or present participle" },
    { tag: "VBN", description: "Verb, past participle" },
    { tag: "VBP", description: "Verb, non-3rd person singular present" },
    { tag: "VBZ", description: "Verb, 3rd person singular present" },
    { tag: "WDT", description: "Wh-determiner" },
    { tag: "WP", description: "Wh-pronoun" },
    { tag: "WP$", description: "Possessive wh-pronoun" },
    { tag: "WRB", description: "Wh-adverb" },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log("Tagger:" + process.env.REACT_APP_TAGGER_ENDPOINT);
        const response = await axios.post(
          `${process.env.REACT_APP_TAGGER_ENDPOINT}/tagger`,
          {
            input: testcase,
          },
        );

        let annotatedTestcase = response.data.annotatedText;
        const regex = new RegExp(inputRegex, "gim");
        annotatedTestcase = annotatedTestcase.replace(
          regex,
          (match) => `<span class="bg-yellow-300">${match}</span>`,
        );

        setData({
          regex: inputRegex,
          testcase: testcase,
          annotatedTestcase: annotatedTestcase,
        });
      } catch (error) {
        console.error("Error annotating text:", error);
      }
    };

    fetchData();
  }, [inputRegex, testcase]);

  return (
    <div className="flex flex-col gap-5 p-5 md:flex-row">
      {/* Main Content: Input Fields and Results Table */}
      <div className="flex-grow">
        <div className="mb-5">
          <label className="my-[10px] mr-5 block">
            Testcase: <br />
            <textarea
              className="my-1 h-[100px] w-4/5 resize-y break-words p-2 text-base leading-relaxed"
              value={testcase}
              onChange={(e) => setTestcase(e.target.value)}
            />
          </label>
        </div>

        <table className="border-collaps w-full table-fixed">
          <thead>
            <tr>
              <th className="border border-gray-300 p-2">Input Regex</th>
              <th className="border border-gray-300 p-2">Testcase</th>
              <th className="border border-gray-300 p-2">Annotated Testcase</th>
            </tr>
          </thead>
          <tbody className="">
            <tr className="">
              <td className="break-words border border-gray-300 p-2">
                {data.regex}
              </td>
              <td className="break-words border border-gray-300 p-2">
                {data.testcase}
              </td>
              <td
                className="max-w-[50px] break-words border border-gray-300 p-2"
                dangerouslySetInnerHTML={{ __html: data.annotatedTestcase }}
              ></td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Tag Legend Section */}
      <div className="w-[300px]">
        <h3>Tag Legend</h3>
        <table className="w-full border-collapse">
          <thead>
            <tr>
              <th className="w-1/5 border border-gray-300 p-2">Tag</th>
              <th className="w-4/5 border border-gray-300 p-2">Description</th>
            </tr>
          </thead>
          <tbody>
            {tagLegend.map((item, index) => (
              <tr key={index}>
                <td className="w-1/5 border border-gray-300 p-2">{item.tag}</td>
                <td className="w-4/5 border border-gray-300 p-2">
                  {item.description}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Regex;
