import { useState, useEffect, useRef } from "react";
import { handleContextMenu } from "./contextMenuUtils";

// -------------------------------------
// 1) Menu Item Component
// -------------------------------------
const MenuItem = ({ icon, label, onClick, shortcut }) => (
  <button
    role="menuitem"
    onClick={onClick}
    className="flex w-full items-center justify-between rounded-md px-3 py-2 text-sm text-gray-700 transition-colors hover:bg-gray-50 focus:bg-gray-100 focus:outline-none"
  >
    <div className="flex items-center gap-2">
      <span className="text-base">{icon}</span>
      {label}
    </div>
    {shortcut && <kbd className="text-xs text-gray-400">{shortcut}</kbd>}
  </button>
);

// -------------------------------------
// 2) Main Dictionary Component
// -------------------------------------
const Dictionary = () => {
  const [text, setText] = useState("");
  const [chosenWord, setChosenWord] = useState("");
  const [reloadKey, setReloadKey] = useState(0);
  const [searchType, setSearchType] = useState("");
  const [isIframeLoading, setIsIframeLoading] = useState(false);
  const longPressTimerRef = useRef(null);
  const [contextMenuPos, setContextMenuPos] = useState({ x: 0, y: 0 });
  const [showMenu, setShowMenu] = useState(false);
  const [selectedWord, setSelectedWord] = useState("");
  const [copySuccess, setCopySuccess] = useState("");

  // Handle in-app lookups (iframe based)
  const handleMenuClick = (type) => {
    const cleanedWord = selectedWord.replace(/[^\w'-]/g, "");
    setChosenWord(cleanedWord);
    setSearchType(type);
    setReloadKey((prev) => prev + 1);
    setShowMenu(false);
  };

  const handleTouchStart = (e) => {
    longPressTimerRef.current = setTimeout(() => {
      // Trigger the context menu after 500ms
      // Pass the event to handleContextMenu so it can calculate the position
      handleContextMenu(e, { setShowMenu, setSelectedWord, setContextMenuPos });
    }, 500);
  };

  const handleTouchEnd = () => {
    if (longPressTimerRef.current) {
      clearTimeout(longPressTimerRef.current);
      longPressTimerRef.current = null;
    }
  };

  const handleTouchMove = () => {
    if (longPressTimerRef.current) {
      clearTimeout(longPressTimerRef.current);
      longPressTimerRef.current = null;
    }
  };

  // Keyboard navigation for menu items
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (!showMenu) return;

      const items = Array.from(document.querySelectorAll('[role="menuitem"]'));
      const currentIndex = items.indexOf(document.activeElement);

      switch (e.key) {
        case "Escape":
          setShowMenu(false);
          break;
        case "ArrowDown":
          e.preventDefault();
          items[(currentIndex + 1) % items.length]?.focus();
          break;
        case "ArrowUp":
          e.preventDefault();
          items[(currentIndex - 1 + items.length) % items.length]?.focus();
          break;
        case "Enter":
          e.preventDefault();
          document.activeElement?.click();
          break;
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [showMenu]);

  // Auto-focus first menu item when menu is shown
  useEffect(() => {
    if (showMenu) {
      const firstMenuItem = document.querySelector('[role="menuitem"]');
      firstMenuItem?.focus();
    }
  }, [showMenu]);

  // Hide context menu on window resize to prevent misplacement
  useEffect(() => {
    const handleResize = () => {
      if (showMenu) setShowMenu(false);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [showMenu]);

  // Render the context menu with grouped items:
  // Group 1: In-app lookup (Definitions & Collocations)
  // Group 2: External dictionary sites (Cambridge, Longman, Google)
  // Group 3: Copy Word
  const renderContextMenu = () => (
    <div
      role="menu"
      className="fixed z-50 w-48 origin-top rounded-lg border border-gray-200 bg-white p-2 shadow-lg transition-all duration-150"
      style={{
        left: contextMenuPos.x,
        top: contextMenuPos.y,
        opacity: showMenu ? 1 : 0,
        transform: `scale(${showMenu ? 1 : 0.95})`,
        pointerEvents: showMenu ? "auto" : "none",
      }}
    >
      {/* Group 1: In-App Lookups */}
      <MenuItem
        icon="📖"
        label="Merriam-Webster"
        onClick={() => handleMenuClick("definitions")}
        shortcut="D"
      />
      <MenuItem
        icon="🔗"
        label="Collocations"
        onClick={() => handleMenuClick("collocations")}
        shortcut="C"
      />

      <div className="my-1 border-t border-gray-200"></div>

      {/* Group 2: External Dictionary Sites */}
      <MenuItem
        icon="📘"
        label="Cambridge Dictionary"
        onClick={() => {
          window.open(
            `https://dictionary.cambridge.org/us/dictionary/english/${selectedWord}`,
            "_blank",
          );
          setShowMenu(false);
        }}
        shortcut="M"
      />
      <MenuItem
        icon="📗"
        label="Longman Dictionary"
        onClick={() => {
          window.open(
            `https://www.ldoceonline.com/dictionary/${selectedWord}`,
            "_blank",
          );
          setShowMenu(false);
        }}
        shortcut="L"
      />
      <MenuItem
        icon="🔍"
        label="Google It"
        onClick={() => {
          window.open(
            `https://www.google.com/search?q=${selectedWord}`,
            "_blank",
          );
          setShowMenu(false);
        }}
        shortcut="G"
      />

      <div className="my-1 border-t border-gray-200"></div>

      {/* Group 3: Copy Word */}
      <MenuItem
        icon="📋"
        label="Copy Word"
        onClick={() => {
          navigator.clipboard.writeText(selectedWord);
          setCopySuccess(`Copied "${selectedWord}" to clipboard!`);
          setTimeout(() => setCopySuccess(""), 1500);
          setShowMenu(false);
        }}
      />
    </div>
  );

  return (
    <div
      className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-50 p-8"
      onClick={() => setShowMenu(false)}
    >
      {/* Header Section */}
      <div className="mx-auto mb-10 max-w-7xl rounded-xl bg-white p-8 shadow-xl">
        <h1 className="mb-4 text-center text-4xl font-extrabold text-indigo-700">
          Enhanced Word Dictionary
        </h1>
        <p className="text-center text-lg text-gray-600">
          Right-click any word for instant dictionary features
        </p>
      </div>

      {/* Main Content Grid */}
      <div className="mx-auto grid max-w-7xl grid-cols-1 gap-8 lg:grid-cols-2">
        {/* Input Panel */}
        <div className="rounded-xl bg-white p-8 shadow-xl">
          <textarea
            value={text}
            onChange={(e) => setText(e.target.value)}
            onContextMenu={(e) =>
              handleContextMenu(e, {
                setShowMenu,
                setSelectedWord,
                setContextMenuPos,
              })
            }
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
            onTouchMove={handleTouchMove}
            placeholder="Paste or type your text here..."
            className="h-80 w-full resize-none rounded-xl border border-gray-300 p-4 text-base shadow-md selection:bg-indigo-100 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          />
        </div>

        {/* Results Panel */}
        <div className="rounded-xl bg-white p-8 shadow-xl">
          <div className="mb-6 flex flex-col items-center gap-4">
            <h2 className="text-2xl font-bold text-indigo-700">
              {chosenWord ? (
                <span className="text-indigo-500">{chosenWord}</span>
              ) : (
                "Select a word to begin"
              )}
            </h2>

            <div className="flex gap-3"></div>
          </div>

          {/* Content Display Area */}
          {searchType === "definitions" || searchType === "collocations" ? (
            <iframe
              key={reloadKey}
              src={
                searchType === "definitions"
                  ? `https://www.merriam-webster.com/dictionary/${chosenWord}`
                  : `https://www.freecollocation.com/search?word=${chosenWord}`
              }
              onLoad={() => setIsIframeLoading(false)}
              className="h-[500px] w-full rounded-xl border border-gray-300 shadow-md"
              title="Dictionary Content"
            />
          ) : (
            <div className="rounded-xl border border-gray-200 bg-gray-50 p-4 text-center text-gray-600 shadow-md">
              Select a word to view its dictionary information
            </div>
          )}
        </div>
      </div>

      {/* Context Menu */}
      {renderContextMenu()}

      {/* Global Loading Overlay */}
      {isIframeLoading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-white/80 backdrop-blur-sm">
          <div className="flex flex-col items-center gap-3 rounded-2xl bg-white p-6 shadow-xl">
            <div className="h-10 w-10 animate-spin rounded-full border-4 border-indigo-500 border-t-transparent"></div>
            <p className="text-gray-600">Analyzing "{chosenWord}"...</p>
          </div>
        </div>
      )}

      {/* Copy Success Toast */}
      {copySuccess && (
        <div className="fixed bottom-5 left-1/2 -translate-x-1/2 transform rounded bg-green-500 px-4 py-2 text-white shadow-lg">
          {copySuccess}
        </div>
      )}
    </div>
  );
};

export default Dictionary;
