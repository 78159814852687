import React from "react";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { Sentence } from "./Sentence"; // Adjust the path as needed

export interface SentenceDisplayProps {
  sentence: Sentence;
  isHovered: boolean;
  onHover: (id: string | number) => void;
  onLeave: () => void;
}

export const SentenceDisplay: React.FC<SentenceDisplayProps> = ({
  sentence,
  isHovered,
  onHover,
  onLeave,
}) => {
  const sentenceContent = (
    <span
      className={`relative inline rounded border px-1.5 py-0.5 text-base transition-colors duration-200 ${
        isHovered ? "bg-blue-200" : ""
      } ${
        sentence.icon === "tick" || sentence.icon === "NA"
          ? "border-blue-300"
          : "border-red-500"
      }`}
      onMouseEnter={() => onHover(sentence.uniqueId)}
      onMouseLeave={onLeave}
    >
      <span
        className={`absolute -top-2.5 left-1 bg-white px-1 text-xs font-medium ${
          sentence.icon === "tick" || sentence.icon === "NA"
            ? "text-blue-500"
            : "text-red-500"
        }`}
      >
        {sentence.id}
      </span>
      {sentence.text}{" "}
    </span>
  );

  return (
    <>
      {/* Desktop/Tablet display */}
      <span className="hidden md:inline">{sentenceContent}</span>
      {/* Mobile display: Wrap with Tippy if annotation exists */}
      <span className="md:hidden">
        {sentence.annotation?.trim() ? (
          <Tippy
            content={
              <div
                className={`p-2 text-sm ${
                  sentence.icon === "tick" ? "text-white" : "text-red-500"
                }`}
              >
                {sentence.annotation}
              </div>
            }
            interactive={true}
            trigger="mouseenter"
          >
            {sentenceContent}
          </Tippy>
        ) : (
          sentenceContent
        )}
      </span>
    </>
  );
};
