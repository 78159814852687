import React, { useState } from "react";
import "tippy.js/dist/tippy.css";
import { sentences } from "./sentencesData";
import { Sentence } from "./Sentence";
import { SentenceDisplay } from "./SentenceDisplay";
import { AnnotationBlock } from "./AnnotationBlock";
import { essays, Essay } from "./essayConfig";

function groupParagraphs(sentences: Sentence[]): Sentence[][] {
  const paragraphs: Sentence[][] = [];
  let current: Sentence[] = [];

  for (const s of sentences) {
    if (s.isParagraphStart && current.length > 0) {
      paragraphs.push(current);
      current = [];
    }
    current.push(s);
  }
  if (current.length > 0) {
    paragraphs.push(current);
  }

  return paragraphs;
}

const Annotate: React.FC = () => {
  const [hoveredId, setHoveredId] = useState<string | number | null>(null);
  const [selectedEssayId, setSelectedEssayId] = useState<string | null>(null);

  const filteredSentences = sentences.filter(
    (s) => s.title === selectedEssayId,
  );

  const paragraphs = groupParagraphs(filteredSentences);

  const essayConfig: Essay | undefined = essays.find(
    (essay) => essay.id === selectedEssayId,
  );

  return (
    <>
      {/* Buttons */}
      <div className="my-6 flex flex-wrap justify-center gap-4">
        {essays.map((essay) => (
          <button
            key={essay.id}
            onClick={() => setSelectedEssayId(essay.id)}
            className={`rounded-lg px-4 py-2 font-semibold text-white shadow-md transition duration-200 focus:outline-none focus:ring-2 ${
              selectedEssayId === essay.id
                ? "bg-blue-600 focus:ring-blue-300"
                : "bg-green-500 hover:bg-green-600 focus:ring-green-300"
            }`}
          >
            Essay {essay.id}
          </button>
        ))}
      </div>

      {selectedEssayId && (
        <div className="mx-auto max-w-6xl p-6">
          <h1 className="mb-8 text-center text-2xl font-bold text-gray-800">
            {essayConfig?.fullTitle || "Essay Title Here"}
          </h1>

          <div className="mt-6 space-y-12">
            {paragraphs.map((paragraph, idx) => (
              <div key={idx} className="grid grid-cols-1 gap-12 md:grid-cols-2">
                <div className="rounded-lg bg-white p-8 shadow">
                  <p className="text-gray-800">
                    {paragraph.map((sentence) => {
                      const isHovered = hoveredId === sentence.uniqueId;

                      return (
                        <React.Fragment key={sentence.uniqueId}>
                          <SentenceDisplay
                            sentence={sentence}
                            isHovered={isHovered}
                            onHover={setHoveredId}
                            onLeave={() => setHoveredId(null)}
                          />
                        </React.Fragment>
                      );
                    })}
                  </p>
                </div>

                <div className="hidden font-comic md:block">
                  {paragraph.map((sentence) => {
                    const { uniqueId, annotation } = sentence;
                    if (!annotation) return null;

                    const isHovered = hoveredId === uniqueId;

                    return (
                      <AnnotationBlock
                        key={sentence.uniqueId}
                        sentence={sentence}
                        isHovered={isHovered}
                        onHover={setHoveredId}
                        onLeave={() => setHoveredId(null)}
                      />
                    );
                  })}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default Annotate;
