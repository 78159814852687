import { useState, useEffect, useRef } from "react";
import { KeyIcon, ClockIcon } from "@heroicons/react/outline";
import Modal from "react-modal";
Modal.setAppElement("#root");

function TextToSpeech() {
  const [text, setText] = useState("");
  const [audioUrls, setAudioUrls] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPlayingIndex, setCurrentPlayingIndex] = useState(-1);
  const [chunksLength, setChunksLength] = useState();
  const [ai302ApiKey, setAi302ApiKey] = useState(
    localStorage.getItem("ai302ApiKey"),
  );
  const [apiKeyExpiry, setApiKeyExpiry] = useState(
    localStorage.getItem("ai302ApiKeyExpiry"),
  );
  const [draftApiKey, setDraftApiKey] = useState(ai302ApiKey);
  const [showModal, setShowModal] = useState(false);
  const [expiryDuration, setExpiryDuration] = useState(
    localStorage.getItem("apiKeyChoice"),
  );
  const [apiKeyChoice, setApiKeyChoice] = useState(
    localStorage.getItem("apiKeyChoice"),
  );
  const [showRevokeConfirmation, setShowRevokeConfirmation] = useState(false);
  const audioRefs = useRef({});
  const durationsRef = useRef([]);
  const API_URL = `${process.env.REACT_APP_SERVER_ENDPOINT}`;
  const MAX_CHARS = 4096;
  const [expiryDisplay, setExpiryDisplay] = useState("");
  useEffect(() => {
    if (!apiKeyExpiry) return;
    const interval = setInterval(() => {
      const remaining = apiKeyExpiry - Date.now();
      if (remaining <= 0) {
        handleRevoke();
        clearInterval(interval);
      } else {
        setExpiryDisplay(
          `${Math.floor(remaining / 60000)}m ${Math.floor((remaining % 60000) / 1000)}s remaining`,
        );
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [apiKeyExpiry]);

  const replacements = ["&", "<", ">"];

  const getExpiryInMs = (value) => {
    const map = {
      "30m": 30 * 60 * 1000,
      "2h": 2 * 60 * 60 * 1000,
      "12h": 12 * 60 * 60 * 1000,
      "1d": 24 * 60 * 60 * 1000,
      "20s": 20 * 1000,
      never: null,
    };
    return map[value];
  };

  const splitTextIntoChunks = (text, maxChars) => {
    const chunks = [];
    let start = 0;
    while (start < text.length) {
      let end = start + maxChars;
      if (end > text.length) end = text.length;
      if (end < text.length) {
        while (end > start && text[end] !== " ") {
          end--;
        }
        if (end === start) end = start + maxChars;
      }
      chunks.push(text.slice(start, end));
      start = end + 1;
    }
    return chunks;
  };
  const getExpiryDisplay = (choice) => {
    if (!choice) return "Not set";
    const map = {
      "20s": "Expires in 20 seconds",
      "30m": "Expires in 30 minutes",
      "2h": "Expires in 2 hours",
      "12h": "Expires in 12 hours",
      "1d": "Expires in 1 day",
      never: "Never expires",
    };
    return map[choice] || "Unknown";
  };
  const handleRevoke = () => {
    setAi302ApiKey("");
    setDraftApiKey("");
    setApiKeyChoice("");
    setApiKeyExpiry(null);

    localStorage.removeItem("ai302ApiKey");
    localStorage.removeItem("ai302ApiKeyExpiry");
    setShowRevokeConfirmation(false);
  };

  const handlePlay = (index) => {
    Object.keys(audioRefs.current).forEach((key) => {
      const i = parseInt(key);
      if (i !== index && audioRefs.current[i]) {
        audioRefs.current[i].pause();
      }
      setCurrentPlayingIndex(index);
    });
  };

  const pauseAll = () => {
    Object.values(audioRefs.current).forEach((audio) => {
      if (audio) audio.pause();
    });
  };

  useEffect(() => {}, [expiryDuration]);

  const findPartAndTime = (globalTime) => {
    let cumulative = 0;
    for (let i = 0; i < durationsRef.current.length; i++) {
      const duration = durationsRef.current[i] || 0;
      if (globalTime < cumulative + duration) {
        return { index: i, time: globalTime - cumulative };
      }
      cumulative += duration;
    }
    const lastIndex = durationsRef.current.length - 1;
    return { index: lastIndex, time: durationsRef.current[lastIndex] || 0 };
  };

  const handleRewind = () => {
    if (currentPlayingIndex === -1) return;
    const currentAudio = audioRefs.current[currentPlayingIndex];
    if (!currentAudio) return;
    const wasPlaying = !currentAudio.paused;
    const currentTime = currentAudio.currentTime;
    let cumulative = 0;
    for (let i = 0; i < currentPlayingIndex; i++) {
      cumulative += durationsRef.current[i] || 0;
    }
    const globalTime = cumulative + currentTime;
    let newGlobalTime = globalTime - 10;
    if (newGlobalTime < 0) newGlobalTime = 0;
    const { index, time } = findPartAndTime(newGlobalTime);
    pauseAll();
    setCurrentPlayingIndex(index);
    const targetAudio = audioRefs.current[index];
    if (targetAudio) {
      targetAudio.currentTime = time;
      if (wasPlaying) targetAudio.play();
    }
  };

  const handleForward = () => {
    if (currentPlayingIndex === -1) return;
    const currentAudio = audioRefs.current[currentPlayingIndex];
    if (!currentAudio) return;
    const wasPlaying = !currentAudio.paused;
    const currentTime = currentAudio.currentTime;
    let cumulative = 0;
    for (let i = 0; i < currentPlayingIndex; i++) {
      cumulative += durationsRef.current[i] || 0;
    }
    const globalTime = cumulative + currentTime;
    let newGlobalTime = globalTime + 10;
    const totalDuration = durationsRef.current.reduce(
      (sum, d) => sum + (d || 0),
      0,
    );
    if (newGlobalTime > totalDuration) newGlobalTime = totalDuration;
    const { index, time } = findPartAndTime(newGlobalTime);
    pauseAll();
    setCurrentPlayingIndex(index);
    const targetAudio = audioRefs.current[index];
    if (targetAudio) {
      targetAudio.currentTime = time;
      if (wasPlaying) targetAudio.play();
    }
  };

  const handleGenerate = async () => {
    if (apiKeyExpiry && Date.now() > parseInt(apiKeyExpiry)) {
      handleRevoke();
      return;
    }
    if (!ai302ApiKey) {
      setShowModal(true);
      return;
    }
    if (!text.trim()) {
      alert("Please enter some text");
      return;
    }
    if (audioUrls.length > 0) {
      const confirmReRender = window.confirm(
        "Audio is already rendered. Do you want to re-render and reset playback?",
      );
      if (!confirmReRender) return;
      audioUrls.forEach((url) => URL.revokeObjectURL(url));
      setAudioUrls([]);
    }
    setIsLoading(true);
    setError(null);
    try {
      const chunks = splitTextIntoChunks(text, MAX_CHARS);
      setChunksLength(chunks.length);
      const audioPromises = chunks.map(async (chunk) => {
        const modifiedChunk = replacements.reduce(
          (acc, replacement) => acc.replaceAll(replacement, ""),
          chunk,
        );
        const response = await fetch(`${API_URL}/generate-voice`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-API-Key": ai302ApiKey,
          },
          body: JSON.stringify({ text: modifiedChunk }),
        });
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || "Failed to generate voice");
        }
        const blob = await response.blob();
        return URL.createObjectURL(blob);
      });
      const urls = await Promise.all(audioPromises);
      setAudioUrls(urls);
    } catch (error) {
      console.error("Error:", error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      audioUrls.forEach((url) => URL.revokeObjectURL(url));
    };
  }, [audioUrls]);

  return (
    <div className="flex min-h-screen items-center justify-center bg-gradient-to-br from-blue-50 to-gray-100 p-6">
      <div className="w-full max-w-2xl rounded-xl bg-white p-8 shadow-2xl">
        <h1 className="mb-3 text-3xl font-bold text-gray-800">
          Text to Speech
        </h1>
        <p className="mb-4 text-lg text-gray-500">
          Powered by Azure via 302ai.com
        </p>
        <div className="mb-4 flex justify-end">
          <button
            onClick={() => {
              if (apiKeyExpiry && Date.now() > parseInt(apiKeyExpiry)) {
                setDraftApiKey("");
                setApiKeyChoice("");
                setShowModal(true);
              } else {
                setShowModal(true);
              }
            }}
            className="flex items-center gap-1.5 rounded-md border border-gray-200 bg-white px-3 py-1.5 text-sm font-medium text-blue-600 shadow-sm transition-all hover:bg-blue-50 hover:text-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-1"
          >
            <KeyIcon className="h-4 w-4" />
            Set API Key
          </button>
        </div>

        {/* API Key Modal */}
        {showModal && (
          <Modal
            isOpen={showModal}
            onRequestClose={() => setShowModal(false)}
            overlayClassName="fixed bg-black/60 top-0 bottom-0 left-0 right-0"
            className="absolute left-1/2 top-1/2 w-[90%] max-w-md -translate-x-1/2 -translate-y-1/2 transform rounded-xl bg-white shadow-2xl"
          >
            <div className="max-w-md rounded-xl bg-white p-6 shadow-2xl transition-all duration-300 sm:p-7">
              <h2 className="mb-2 text-xl font-bold text-gray-800">
                Enter API Key
              </h2>
              <p className="mb-5 text-sm text-gray-600">
                Enter your 302ai key to use the Text to Speech service.
              </p>
              {apiKeyExpiry && (
                <div className="flex items-center gap-1.5 text-sm italic text-gray-500">
                  <ClockIcon className="h-4 w-4" />
                  <span>{expiryDisplay}</span>
                </div>
              )}

              {ai302ApiKey ? (
                <div className="mb-4 rounded-md bg-blue-50 p-3 text-sm text-blue-800">
                  <span className="font-medium">Current expiry setting:</span>{" "}
                  <span className="font-semibold">
                    {getExpiryDisplay(apiKeyChoice)}
                  </span>
                </div>
              ) : (
                <div className="mb-4 rounded-md bg-amber-50 p-3 text-sm text-amber-800">
                  <span className="font-medium">No active API key found.</span>{" "}
                  Please enter a new one below.
                </div>
              )}

              <div className="mb-5">
                <label
                  htmlFor="apiKeyExpiry"
                  className="mb-1.5 block text-sm font-medium text-gray-700"
                >
                  Key Expiration
                </label>
                <select
                  name="apiKeyExpiry"
                  id="apiKeyExpiry"
                  value={expiryDuration}
                  onChange={(e) => {
                    setExpiryDuration(e.target.value);
                  }}
                  className="w-full rounded-lg border border-gray-300 bg-white p-2.5 text-gray-700 shadow-sm transition-all focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
                >
                  <option value="20s">Expires in 20 seconds</option>
                  <option value="30m">Expires in 30 minutes</option>
                  <option value="2h">Expires in 2 hours</option>
                  <option value="12h">Expires in 12 hours</option>
                  <option value="1d">Expires in 1 day</option>
                  <option value="never">Never expire</option>
                </select>
              </div>

              <div className="mb-5">
                <label
                  htmlFor="apiKey"
                  className="mb-1.5 block text-sm font-medium text-gray-700"
                >
                  302.AI API Key
                </label>
                <input
                  id="apiKey"
                  type="text"
                  value={draftApiKey}
                  onChange={(e) => {
                    setDraftApiKey(e.target.value);
                  }}
                  className="w-full rounded-lg border border-gray-300 p-2.5 shadow-sm transition-all focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
                  placeholder="Your API Key"
                />
              </div>

              <div className="flex justify-between">
                <button
                  onClick={() => {
                    setShowRevokeConfirmation(true);
                  }}
                  className="rounded-lg bg-red-500 px-4 py-2 text-sm font-medium text-white transition-colors duration-200 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-1"
                >
                  Revoke Key
                </button>
                <div className="flex space-x-2">
                  <button
                    onClick={() => {
                      setDraftApiKey(ai302ApiKey);
                      setShowModal(false);
                    }}
                    className="rounded-lg border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 transition-colors duration-200 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-1"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={(e) => {
                      const expiryMs = getExpiryInMs(expiryDuration);
                      const expiryTimestamp = expiryMs
                        ? Date.now() + expiryMs
                        : null;
                      setApiKeyChoice(expiryDuration);

                      localStorage.setItem(
                        "ai302ApiKeyExpiry",
                        expiryTimestamp,
                      );
                      localStorage.setItem("apiKeyChoice", expiryDuration);
                      setAi302ApiKey(draftApiKey);
                      setApiKeyExpiry(expiryTimestamp);
                      localStorage.setItem("ai302ApiKey", draftApiKey);
                      localStorage.setItem(
                        "ai302ApiKeyExpiry",
                        expiryTimestamp,
                      );
                      setShowModal(false);
                    }}
                    className="rounded-lg bg-blue-600 px-4 py-2 text-sm font-medium text-white transition-colors duration-200 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-1"
                  >
                    Save Key
                  </button>
                </div>
              </div>

              {/* Revoke Confirmation Dialog */}
              {showRevokeConfirmation && (
                <div className="absolute inset-0 z-10 flex items-center justify-center rounded-xl bg-white/95">
                  <div className="w-[90%] rounded-lg border border-gray-200 bg-white p-5 shadow-lg">
                    <p className="mb-6 text-center font-medium text-gray-700">
                      Are you sure you want to revoke this API key?
                    </p>
                    <div className="flex justify-center gap-3">
                      <button
                        onClick={() => setShowRevokeConfirmation(false)}
                        className="rounded-lg border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 transition-colors duration-200 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-1"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={handleRevoke}
                        className="rounded-lg bg-red-500 px-4 py-2 text-sm font-medium text-white transition-colors duration-200 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-1"
                      >
                        Revoke Key
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Modal>
        )}

        <div className="mb-5 flex gap-3">
          <button
            onClick={() => setText("")}
            className="flex-1 rounded-lg border border-gray-300 bg-gray-50 px-4 py-2 font-medium text-gray-700 transition hover:bg-gray-100"
          >
            Clear
          </button>
          <button
            onClick={async () => {
              const text = await navigator.clipboard.readText();
              setText(text);
            }}
            className="flex-1 rounded-lg bg-blue-600 px-4 py-2 font-medium text-white transition hover:bg-blue-700"
          >
            Import Text
          </button>
        </div>

        <textarea
          value={text}
          onChange={(e) => setText(e.target.value)}
          placeholder="Enter your text here..."
          className="mb-4 w-full resize-y rounded-md border border-gray-300 p-4 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500"
          rows={5}
        />

        <div className="mb-4 text-sm text-gray-500">
          {text.length} characters
          {text.length > MAX_CHARS &&
            ` (will be split into approximately ${Math.ceil(text.length / MAX_CHARS)} parts)`}
        </div>

        <button
          onClick={handleGenerate}
          disabled={isLoading}
          className="w-full rounded-lg bg-blue-600 px-4 py-2 font-medium text-white shadow transition hover:bg-blue-700 disabled:opacity-50"
        >
          {isLoading ? "Rendering..." : "Generate Audio"}
        </button>

        {error && <p className="mt-2 text-sm text-red-500">{error}</p>}

        {isLoading && (
          <div className="mt-4">
            <div className="h-2.5 w-full rounded-full bg-gray-200">
              <div className="h-2.5 w-1/2 animate-pulse rounded-full bg-blue-600"></div>
            </div>
            <p className="mt-2 text-sm text-gray-600">
              Rendering {Math.ceil(text.length / MAX_CHARS)} audio part
              {Math.ceil(text.length / MAX_CHARS) > 1 ? "s" : ""}...
            </p>
          </div>
        )}

        {audioUrls.length > 0 && !isLoading && (
          <div className="mt-6">
            <div className="mb-4 flex gap-3">
              <button
                onClick={handleRewind}
                className="flex-1 rounded-lg border border-gray-300 bg-gray-50 px-4 py-2 font-medium text-gray-700 transition hover:bg-gray-100"
              >
                Rewind 10s
              </button>
              <button
                onClick={handleForward}
                className="flex-1 rounded-lg border border-gray-300 bg-gray-50 px-4 py-2 font-medium text-gray-700 transition hover:bg-gray-100"
              >
                Forward 10s
              </button>
            </div>
            {audioUrls.map((url, index) => (
              <div key={index} className="mb-4">
                {chunksLength > 1 && (
                  <h3 className="mb-2 text-lg font-semibold text-gray-800">
                    Part {index + 1}
                  </h3>
                )}
                <audio
                  ref={(el) => (audioRefs.current[index] = el)}
                  onPlay={() => handlePlay(index)}
                  onLoadedMetadata={() => {
                    const audio = audioRefs.current[index];
                    if (audio) durationsRef.current[index] = audio.duration;
                  }}
                  controls
                  src={url}
                  className="w-full"
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default TextToSpeech;
