import { Link, Outlet } from "react-router-dom";

const Dashboard = () => {
  return (
    <div className="min-h-screen bg-gray-50 p-4 dark:bg-gray-900">
      {/* Navigation */}
      <div className="justify-around rounded-lg border border-gray-200 p-4 text-blue-600 shadow-md dark:border-gray-700 md:flex">
        <h1 className="m-8 cursor-pointer text-center text-2xl hover:underline dark:text-blue-400">
          <Link to="/dashboard/profile">My Profile</Link>
        </h1>
        <h1 className="m-8 cursor-pointer text-center text-2xl hover:underline dark:text-blue-400">
          <Link to="/dashboard/scores">Scores</Link>
        </h1>
      </div>
      <div className="mt-8">
        <Outlet />
      </div>
    </div>
  );
};

export default Dashboard;
